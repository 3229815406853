import React, { useEffect, useState } from "react";
import {
  getEvent,
  // getMediaByEvent,
  getuserReservationsDetailsByEvent,
} from "../services/api";
import { useParams } from "react-router-dom";
import AppWidgetSummary from "./appwidgetsummarycard";
import icon1 from "../assets/icons/ic_glass_bag.png"
import icon2 from "../assets/icons/ic_glass_buy.png"
import icon3 from "../assets/icons/ic_glass_users.png"
import icon4 from "../assets/icons/ic_glass_message.png"

const CardSectionEvent = ({videoFiles,imageFiles,totalAmount, onRefresh}) => {
  const { eventid } = useParams();
  const [seats, setSeats] = useState(0);
  const [maxSeats, setMaxSeats] = useState(); // eslint-disable-line no-unused-vars
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    const fetchReservations = async () => {
      try {
        const res = await getuserReservationsDetailsByEvent(eventid);
          const response = await getEvent(eventid);
        const maxSeats = response.data.maxSeats || null;
        setMaxSeats(maxSeats);

        const totalSeats = res.data.reduce(
          (acc, reservation) => acc + reservation.size,
          0
        );
        setSeats(totalSeats);
        
        
        if (maxSeats) {
          const percent = (totalSeats / maxSeats) * 100; //.toFixed(2)
          setPercentage(percent);
          
          
        } else {
          setPercentage(null);
       
        }
      } catch (error) {
        console.log(error);
      }
    };

    // const fetchMediaUploads = async () => {
    //   try {
    //     const res = await getMediaByEvent(eventid);
    //     const videoFiles = res.data.filter(
    //       (media) => media.type === "video"
    //     ).length;
    //     const imageFiles = res.data.filter(
    //       (media) => media.type === "image"
    //     ).length;

    //     setVideos(videoFiles);
    //     setImages(imageFiles);
    //   } catch (error) {
    //     console.log(error);
    //   }
    // };

    if (eventid) {
      fetchReservations();
      // fetchMediaUploads();
    }
  }, [eventid,onRefresh]);// eslint-disable-line react-hooks/exhaustive-deps


  const cards = [
    {
      icon:icon1,
      title: "Reservations",
      total: seats,
      color: "from-blue-600 to-blue-400 shadow-blue-500/40",
      bgColor:"#C7CAAA",
      percentage: percentage? percentage : 0,
    },
    {
      icon:icon2,
      title: "Images",
      total: imageFiles.length ? imageFiles.length : 0,
      color: "from-pink-600 to-pink-400 shadow-pink-500/40",
      bgColor:"#F5E7D8",
    },
    {
      icon:icon3,
      title: "Videos",
      total: videoFiles.length ? videoFiles.length : 0,
      color: "from-green-600 to-green-400 shadow-green-500/40",
      bgColor:"#FFF1C9",
    },
    {
      icon: icon4,
      title: "Donations",
      total: totalAmount,
      color: "from-orange-600 to-orange-400 shadow-orange-500/40",
      bgColor:"#E8F5E9",
    },
  ];

  return (
    <div className="mt-12">
      <div className="mb-12 grid gap-y-10 gap-x-6 sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-4">
        {cards.map((card, index) => (
          <AppWidgetSummary
            key={index}
            title={card.title}
            total={card.total}
            color={card.color}
            icon={<img alt="icon" src={`${card.icon}`} />}
            style={{ backgroundColor: `${card.bgColor}` }}
            percentage={card.percentage}
          />
        ))}
      </div>
    </div>
  );
};

export default CardSectionEvent; 
