import React, { useState } from "react";
import "tailwindcss/tailwind.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { createUser } from "../services/api";
import Loader from "./loader";
import {ReactComponent as LadyAuth } from "../assets/svg/ladyauth.svg";
import { BiHide } from "react-icons/bi";
import { GrView } from "react-icons/gr";

const nigerianBanks = [
    "Access Bank",
    "Citibank",
    "Diamond Bank",
    "Ecobank Nigeria",
    "Fidelity Bank Nigeria",
    "First Bank of Nigeria",
    "First City Monument Bank",
    "Guaranty Trust Bank",
    "Heritage Bank Plc",
    "Keystone Bank Limited",
    "Polaris Bank",
    "Providus Bank",
    "Stanbic IBTC Bank Nigeria Limited",
    "Standard Chartered Bank",
    "Sterling Bank",
    "Suntrust Bank Nigeria Limited",
    "Union Bank of Nigeria",
    "United Bank for Africa",
    "Unity Bank Plc",
    "Wema Bank",
    "Zenith Bank",
  ];

const GuestRegister = () => {

    const [viewPassword, setViewPassword] = useState(false);
    const [formData, setFormData] = useState({
        name: "",
        phone: "",
        email: "",
        password: "",
        hostAccountNumber:"",
        hostBankName:"",
      });
    
      const [loading, setLoading] = useState(false);
      const [message, setMessage] = useState("");
      const [error, setError] = useState("");
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };

      const handlePasswordView = () => {
        setViewPassword(!viewPassword);
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          setLoading(true);
          setMessage("Registering User...");
          const response = await createUser(formData);
    
          if (response.data) {
            setLoading(false);
            toast.success("User Successfully Created");
            navigate(-1);
          }
        } catch (error) {
          // console.error("Error submitting form:", error);
          setLoading(false);
          setError("Error submitting form");
          toast.error("Error registering user");
        }
      };
    
    const navigate = useNavigate();

  return (
    <main className=" w-full flex bg-[#fafbe6] flex-row h-screen">
        <div className='w-[50%] h-full overflow-hidden relative hidden items-center justify-center lg:flex'>
            <div className=' h-[120%] w-[140%] absolute left-[-60%] bg-[#D3E537] top-[-20px]' style={{borderRadius:"100%"}}>
                
                
            </div>
            <div className='absolute right-[45%] bottom-0'><LadyAuth/></div>
        </div>
        <div className='w-full lg:w-[50%] flex justify-center align-middle'>
        {loading ? (
        <Loader>{message}</Loader>
            ) : (
            <>
                <div className="w-full max-w-xl space-y-8 px-4 sm:px-0 my-auto">
                        <div className="">
                            {/* <img src="logo.svg" width={150} className="lg:hidden" />Eventaura logo */}
                            <div className="pt-3">
                                <h3 className="text-[#D3E537] font-bold text-2xl md:text-[46px]">Sign Up to Continue</h3>
                            </div>
                        </div>
                    
                        <form
                            onSubmit={handleSubmit}
                            className="space-y-3 md:space-y-5"
                        >
                            <div>
                                <label className="font-medium text-black">
                                    FullName
                                </label>
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="Name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    autoComplete="fullname"
                                    required
                                    className="w-full mt-2 px-3 py-2 text-black bg-transparent outline-none border border-[#D3E537] focus:border-black shadow-sm rounded-lg"
                                />
                            </div>
                            <div>
                                <label className="font-medium text-black">
                                    PhoneNumber
                                </label>
                                <input
                                    type="tel"
                                    name="phone"
                                    placeholder="Phone"
                                    value={formData.phone}
                                    onChange={handleChange}
                                    autoComplete="phone"
                                    required
                                    className="w-full mt-2 px-3 py-2 text-black bg-transparent outline-none border border-[#D3E537] focus:border-black shadow-sm rounded-lg"
                                />
                            </div>
                            <div className="-z-30">
                                <label className="font-medium text-black">Bank Name</label>
                                <select
                                    name="hostBankName"
                                    value={formData.hostBankName}
                                    onChange={handleChange}
                                    required
                                    className="w-full mt-2 px-3 py-2 text-black bg-transparent outline-none border border-[#D3E537] focus:border-black shadow-sm rounded-lg"
                                >
                                    <option value="" disabled>
                                    Select Bank
                                    </option>
                                    {nigerianBanks.map((bank, index) => (
                                    <option key={index} value={bank}>
                                        {bank}
                                    </option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label className="font-medium text-black">
                                    AccountNumber
                                </label>
                                <input
                                    type="text"
                                    name="hostAccountNumber"
                                    placeholder="Account Number"
                                    value={formData.hostAccountNumber}
                                    onChange={handleChange}
                                    required
                                    className="w-full mt-2 px-3 py-2 text-black bg-transparent outline-none border border-[#D3E537] focus:border-black shadow-sm rounded-lg"
                                />
                            </div>
                            <div>
                                <label className="font-medium text-black">
                                    Email
                                </label>
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    autoComplete="email"
                                    required
                                    className="w-full mt-2 px-3 py-2 text-black bg-transparent outline-none border border-[#D3E537] focus:border-black shadow-sm rounded-lg"
                                />
                            </div> 

                            <div className="relative">
                                <label className="font-medium text-black">
                                    Password
                                </label>
                                <input
                                    type={viewPassword ? "text" : "password"}
                                    name="password"
                                    placeholder="Password"
                                    value={formData.password}
                                    onChange={handleChange}
                                    autoComplete="password"
                                    required
                                    className="w-full mt-2 px-3 py-2 text-black bg-transparent outline-none border border-[#D3E537] focus:border-black shadow-sm rounded-lg"
                                />
                                <BiHide size={25} className={`${viewPassword ? 'hidden' : 'absolute bottom-2 right-2 cursor-pointer'} text-[#545822]`} onClick={handlePasswordView} />
                                <GrView size={25} className={`${viewPassword ? 'absolute bottom-2 right-2 cursor-pointer' : 'hidden'} text-[#545822]`} onClick={handlePasswordView} />
                            </div>
                            <button
                                type="submit"
                                className="w-full px-4 py-2 text-black font-medium bg-[#D3E537] hover:bg-[#545822] active:bg-[#D3E537] rounded-lg duration-150"
                            >
                                Sign Up
                            </button>
                            {<p className="text-red-500 text-center">{error}</p>}
                        </form>
                        <ToastContainer />
                </div>
            </>
            )}
        </div>
    </main>
  )
}

export default GuestRegister
