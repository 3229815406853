import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "tailwindcss/tailwind.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlassCheers } from "@fortawesome/free-solid-svg-icons";
import bgImg from "../assets/sisevent.jpg"

const AppLandingPage = () => {
  const navigate = useNavigate();
  const [eventId, setEventId] = useState("");

  const handleCreateEvent = () => {
    navigate("/login");
    // navigate("/createevent");
  };

  const handleJoinEvent = () => {
    navigate(`/guestview/${eventId}`);
  };

  return (
    <div
  className="relative min-h-screen flex flex-col items-center justify-center px-5 bg-cover bg-center"
  style={{ backgroundImage: `url(${bgImg})` }}
>
  {/* Dark Overlay */}
  <div className="absolute inset-0 bg-black bg-opacity-50 z-0"></div>

  {/* Content */}
  <div className="relative z-10 flex flex-col items-start justify-center" >
    <h1 className="text-4xl md:text-5xl font-bold mb-5 text-white text-shadow">
      Create and Join Exciting <span className="text-[#D3E537]">Unforget</span>table <br/> Events with <span className="text-[#D3E537]">EventAura</span>
    </h1>
    <p className="text-[#d3e537] mb-10 text-shadow">
      Connect, collaborate and celebrate from anywhere with EventAura
    </p>
    <div className="flex flex-col space-y-4 md:flex-row md:items-center md:space-x-4 md:space-y-0">
      <button
        className="w-full md:w-auto flex gap-3 items-center px-6 py-3 bg-[#d3e537] text-black text-center rounded-lg hover:bg-[#4f513a] focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2"
        onClick={handleCreateEvent}
      >
        <FontAwesomeIcon icon={faGlassCheers} />
        New event
      </button>

      <div className="relative w-full md:flex-1">
        <input
          className="w-full px-4 py-3 pr-24 bg-white border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-black focus:border-black"
          type="text"
          placeholder="Enter EventId"
          value={eventId}
          onChange={(e) => setEventId(e.target.value)}
        />
        <button
          className="absolute right-2 top-1/2 transform -translate-y-1/2 px-4 py-2 bg-[#4f513a] text-[#d3e537] rounded-lg hover:bg-gray-300 hover:text-black focus:outline-none focus:ring-2 focus:ring-gray-400"
          onClick={handleJoinEvent}
        >
          Join
        </button>
      </div>
    </div>
  </div>
</div>
  );
};

export default AppLandingPage;
