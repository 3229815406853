import React, { useState } from "react";
import useStore from "../store/event-store";
import { uploadMedia } from "../services/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./loader";

const UploadComponent = ({ closeUploadBox, eventid }) => {
  const reservationphone = useStore((state) => state.reservationphone);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const { guestname } = useStore();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles(files);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if guest name is present before proceeding with the upload
    if (!guestname || guestname.trim() === "") {
      setLoading(false); // Stop loading when there's an issue
      toast.error(
        "Please accept the invitation first to proceed with media uploads."
      );
      return;
    }

    if (selectedFiles.length > 10) {
      toast.error("You can only upload a maximum of 10 files.");
      return;
    }

    if (selectedFiles.length < 1) {
      toast.error("You need to upload a minimum of 1 file.");
      return;
    }

    const formData = new FormData();
    formData.append("phone", reservationphone);
    formData.append("eventid", eventid);
    selectedFiles.forEach((file, index) => {
      formData.append("files", file);
    });
    // console.log(formData);
    // try {
    //   setLoading(true);
    //   setMessage("Uploading media...");
    //   if (!guestname || guestname === "") {
    //     toast.error(
    //       "Please accept the invitation first to proceed with media uploads."
    //     );
    //     return;
    //   }
    //   const response = await uploadMedia(formData);
    //   if (response.data) {
    //     closeUploadBox();
    //     setLoading(false);
    //     toast.success("Media upload successful");
    //   }
    // } catch (error) {
    //   setLoading(false);
    //   console.error("Error uploading media:", error);
    //   toast.error("Error uploading media");
    // }
    try {
      setLoading(true);
      setMessage("Uploading media...");

      const response = await uploadMedia(formData);

      if (response.data) {
        closeUploadBox(); // Close the upload box after successful upload
        toast.success("Media uploaded successfully!");
      } else {
        toast.error("Something went wrong. Please try again.");
      }
    } catch (error) {
      setLoading(false); // Ensure loading is stopped in case of an error
      toast.error(
        "An error occurred during the upload. Please try again later."
      );
    } finally {
      setLoading(false); // Ensure loading is stopped after everything is complete
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      {loading ? (
        <Loader>{message}</Loader>
      ) : (
        <div className="bg-white p-4 rounded-lg shadow-lg sm:w-3/4 md:w-1/3 lg:w-1/4 relative z-50">
          <div className="flex justify-end">
            <button
              onClick={closeUploadBox}
              className="px-4 py-2 bg-red-500 text-white hover:bg-red-700 transition rounded"
            >
              Close
            </button>
          </div>
          <h2 className="text-xl font-semibold text-center">
            Upload Event Images/Videos
          </h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-gray-700">
                Select Files (Max 10):
              </label>
              <input
                type="file"
                onChange={handleFileChange}
                className="mt-1 p-2 border rounded w-full"
                accept="image/*,video/*,audio/*"
                multiple
                required
              />
            </div>
            <button
              type="submit"
              className="px-4 py-2 bg-green-600 text-white hover:bg-[#d3e999] transition rounded"
            >
              Upload
            </button>
          </form>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default UploadComponent;
