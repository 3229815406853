import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "tailwindcss/tailwind.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { loginApi } from "../services/api";
import Loader from "./loader";
import useStore from "../store/event-store";
import {ReactComponent as LadyAuth } from "../assets/svg/ladyauth.svg"
import { BiHide } from "react-icons/bi";
import { GrView } from "react-icons/gr";

const Login = () => {

  const [viewPassword, setViewPassword] = useState(false);
  const setHostPhoneNumber = useStore((state) => state.setHostPhoneNumber);
  const { login } = useStore();
  const [formData, setFormData] = useState({
    phone: "",
    password: "",
  });

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };


  const handlePasswordView = () => {
    setViewPassword(!viewPassword);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      setMessage("Confirming User...");
      const response = await loginApi(formData);
      if (response.data) {
        login(response.data);
        setLoading(false);
        setHostPhoneNumber(response.data.phone);
        toast.success("User Successfully Logged in");
        navigate("/events");
      }
    } catch (error) {
      // console.error("Error submitting form:", error);
      setLoading(false);
      // setError(error.response.data.error);
      toast.error("Error identifying user");
    }
  };

  const navigate = useNavigate();

  return (
    <main className=" w-full flex bg-[#545822] flex-row h-screen">
        <div className='w-[50%] h-full overflow-hidden relative hidden items-center justify-center lg:flex'>
            <div className=' h-[120%] w-[140%] absolute left-[-60%] bg-[#D3E537] top-[-20px]' style={{borderRadius:"100%"}}>
                
                
            </div>
            <div className='absolute right-[45%] bottom-0'><LadyAuth/></div>
        </div>
        <div className='w-full lg:w-[50%] flex justify-center align-middle'>
        {loading ? (
            <Loader>{message}</Loader>
            ) : (
            <>
                <div className="w-full max-w-xl space-y-8 px-4 sm:px-0 my-auto">
                        <div className="">
                            {/* <img src="logo.svg" width={150} className="lg:hidden" />Eventaura logo */}
                            <div className="mt-5 space-y-2">
                                <p className="text-[#D3E537] font-bold text-3xl sm:text-4xl lg:text-5xl">Sign In to Your Account</p>
                                <p className="text-white">Dont have an account Yet? <span className="pl-2"> <Link to={"/registration"} className="font-medium text-[#D3E537] hover:text-white text-xl underline "> Sign Up</Link></span></p>
                            </div>
                        </div>
                    
                        <form
                            onSubmit={handleSubmit}
                            className="space-y-5"
                        >
                            <div>
                                <label className="font-medium text-white">
                                    PhoneNumber
                                </label>
                                <input
                                    type="tel"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleChange}
                                    placeholder="PhoneNumber"
                                    autoComplete="phonenumber"
                                    required
                                    className="w-full mt-2 px-3 py-2 text-white bg-transparent outline-none border border-[#D3E537] focus:border-white shadow-sm rounded-lg"
                                />
                            </div>
                            
                            <div className="relative">
                                <label className="font-medium text-white">
                                    Password
                                </label>
                                <input
                                   type={viewPassword ? "text" : "password"}
                                   name="password"
                                   value={formData.password}
                                   onChange={handleChange}
                                   placeholder="Password"
                                   autoComplete="password"
                                   required
                                   className="w-full mt-2 px-3 py-2 text-white bg-transparent outline-none border border-[#D3E537] focus:border-white shadow-sm rounded-lg"
                                />
                                <BiHide size={25} className={`${viewPassword ? 'hidden' : 'absolute bottom-2 right-2 cursor-pointer'} text-[#D3E537]`} onClick={handlePasswordView} />
                                <GrView size={25} className={`${viewPassword ? 'absolute bottom-2 right-2 cursor-pointer' : 'hidden'} text-[#D3E537]`} onClick={handlePasswordView} />
                            </div>
                            <button
                                type="submit"
                                className="w-full px-4 py-2 text-black font-medium bg-[#D3E537] hover:bg-white active:bg-[#D3E537] rounded-lg duration-150"
                            >
                                Sign In
                            </button>
                            {<p className="text-red-500 text-center">{error}</p>}
                        </form>
                </div>
            </>
            )}
            <ToastContainer />
        </div>
    </main>
 

  )
}

export default Login

 //   <main className="w-full flex bg-[#545822] flex-row h-screen">
  // <div className="w-[50%] h-full overflow-hidden relative hidden items-center justify-center lg:flex">
  //   <div
  //     className="h-[120%] w-[140%] absolute left-[-40%] lg:left-[-60%] xl:left-[-80%] bg-[#D3E537] top-[-20px]"
  //     style={{ borderRadius: "100%" }}
  //   ></div>
  //   <div className="absolute right-[35%] lg:right-[45%] xl:right-[55%] bottom-0">
  //     <LadyAuth />
  //   </div>
  // </div>
  // <div className="w-full lg:w-[50%] flex justify-center align-middle">
  //   {loading ? (
  //     <Loader>{message}</Loader>
  //   ) : (
  //     <>
  //       <div className="w-full max-w-xl space-y-8 px-4 sm:px-0 my-auto">
  //         <div className="">
  //           <div className="mt-5 space-y-2">
  //             <p className="text-[#D3E537] font-bold text-3xl sm:text-4xl lg:text-5xl">
  //               Sign In to Your Account
  //             </p>
  //             <p className="text-white">
  //               Don't have an account yet?
  //               <span className="pl-2">
  //                 {" "}
  //                 <Link
  //                   to={"/registration"}
  //                   className="font-medium text-[#D3E537] hover:text-white text-xl underline"
  //                 >
  //                   Sign Up
  //                 </Link>
  //               </span>
  //             </p>
  //           </div>
  //         </div>

  //         <form onSubmit={handleSubmit} className="space-y-5">
  //           <div>
  //             <label className="font-medium text-white">PhoneNumber</label>
  //             <input
  //               type="tel"
  //               name="phone"
  //               value={formData.phone}
  //               onChange={handleChange}
  //               placeholder="PhoneNumber"
  //               autoComplete="phonenumber"
  //               required
  //               className="w-full mt-2 px-3 py-2 text-white bg-transparent outline-none border border-[#D3E537] focus:border-white shadow-sm rounded-lg"
  //             />
  //           </div>

  //           <div>
  //             <label className="font-medium text-white">Password</label>
  //             <input
  //               type="password"
  //               name="password"
  //               value={formData.password}
  //               onChange={handleChange}
  //               placeholder="Password"
  //               autoComplete="password"
  //               required
  //               className="w-full mt-2 px-3 py-2 text-white bg-transparent outline-none border border-[#D3E537] focus:border-white shadow-sm rounded-lg"
  //             />
  //           </div>
  //           <button
  //             type="submit"
  //             className="w-full px-4 py-2 text-black font-medium bg-[#D3E537] hover:bg-white active:bg-[#D3E537] rounded-lg duration-150"
  //           >
  //             Sign In
  //           </button>
  //           {<p className="text-red-500 text-center">{error}</p>}
  //         </form>
  //       </div>
  //     </>
  //   )}
  //   <ToastContainer />
  // </div>
  //   </main>
