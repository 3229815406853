import React, { useEffect, useState } from "react";
import { getEventQR } from "../services/api";
import useStore from "../store/event-store";
import DOMPurify from "dompurify"; //DOMPurify sanitize the SVG content to ensure it doesn't contain any harmful scripts.

const ReservationPass = ({ eventid, closeModal }) => {
  const { reservationphone } = useStore();
  const [svg, setSvg] = useState(null);

  useEffect(() => {
    const fetchEventQR = async () => {
      try {
        const response = await getEventQR(eventid, reservationphone);
        // Sanitize the SVG content before setting it
        const { Qrcode } = response.data;
        const sanitizedSvg = DOMPurify.sanitize(Qrcode);
        setSvg(sanitizedSvg);
      } catch (error) {
        console.error("Error fetching event QR:");
      }
    };

    fetchEventQR();
  }, [eventid, reservationphone]);

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-4 rounded-lg shadow-lg sm:w-3/4 md:w-1/3 lg:w-1/4 relative z-50">
        <div className="flex justify-end">
          <button
            onClick={closeModal}
            className="px-4 py-2 bg-red-500 text-white hover:bg-red-700 transition rounded"
          >
            Close
          </button>
        </div>
        <h2 className="text-xl font-semibold text-center mb-4">
          Your Reservation Pass
        </h2>
        <div className="flex justify-center mb-4">
          {svg ? (
            <div dangerouslySetInnerHTML={{ __html: svg }} />
          ) : (
            <div>
              <p className="text-center text-red-700 text-xl">
                Error Occured!!!
              </p>
              <p className="text-center text-red-700">
                You need to make accept invitation, before getting an Pass Id
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReservationPass;
