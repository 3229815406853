// src/VideoCard.js
import React from "react";

const VideoCard = ({ video, onClick }) => {
  return (
    <div
      className="rounded-lg shadow-lg overflow-hidden cursor-pointer"
      onClick={onClick}
    >
      <video className="w-full h-48 object-cover" src={video.url} />

      <div className="p-3">
        <p>{video.description}</p>
      </div>
    </div>
  );
};

export default VideoCard;
