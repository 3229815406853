import { Typewriter } from "react-simple-typewriter";
import { getEvent } from "../services/api";
import { useEffect, useState } from "react";

const templates = {
  wedding: {
    name: "Wedding",
    template:
      "The Families of [groomFamily] and [brideFamily] Cordially Invite You to Celebrate the Union of [groom] & [bride]. Date: [date]. Time: [time]. Location: [location]. We look forward to sharing this special moment with you.",
    fields: {
      groomFamily: "Groom's Family",
      brideFamily: "Bride's Family",
      groom: "Groom's Name",
      bride: "Bride's Name",
      time: "time",
      location: "Event Location",
      date: "date",
    },
  },
  birthday: {
    name: "Birthday",
    template:
      "Join us for a fun-filled birthday celebration for [name]! Date: [date]. Time: [time]. Location: [location]. We can't wait to celebrate with you!",
    fields: {
      name: "Birthday Person's Name",
      date: "date",
      time: "time",
      location: "Event Location",
    },
  },
  anniversary: {
    name: "Anniversary",
    template:
      "Celebrate the [years] years of love between [names]! Date: [date]. Time: [time]. Location: [location]. We look forward to celebrating with you.",
    fields: {
      years: "Number of Years",
      names: "Names of Couple",
      eventTime: "Event Time",
      location: "Event Location",
      date: "date",
      time: "time",
    },
  },
  festival: {
    name: "Festival",
    template:
      "Join us for the [festivalName] Festival! Date: [date]. Time: [time]. Location: [location]. We can't wait to celebrate with you!",
    fields: {
      festivalName: "Festival Name",
      time: "time",
      location: "Event Location",
      date: "date",
    },
  },
};

const PhoneMockup = ({ eventid }) => {
  const [eventData, setEventData] = useState({}); // eslint-disable-line no-unused-vars
  const [template, setTemplate] = useState("Welcome To Eventaura");

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const response = await getEvent(eventid);
        const data = response.data;
        setEventData(data);

        const eventType = data.type;
        const template = templates[eventType];

        if (template) {
          let filledTemplate = template.template;
          Object.keys(template.fields).forEach((key) => {
            let value = data[key] || template.fields[key];
            if (key === "date") {
              value = new Date(value).toLocaleDateString(undefined, {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
            }
            filledTemplate = filledTemplate.replace(`[${key}]`, value);
          });

          setTemplate(filledTemplate);
        }
      } catch (error) {
        console.error("Error fetching event data:", error);
      }
    };

    if (eventid) {
      fetchEventData();
    }
  }, [eventid]);

  return (
    <div className="flex justify-center items-center">
      <div className="w-[20rem] md:w-[500px] h-[40rem] border-4 border-gray-900 rounded-3xl bg-black relative">
        <div className="absolute top-4 left-1/2 transform -translate-x-1/2 w-24 h-4 bg-gray-900 rounded-full"></div>
        <div className="absolute top-10 left-1/2 transform -translate-x-1/2 w-6 h-6 bg-gray-900 rounded-full"></div>
        <div className="absolute inset-x-0 top-20 bottom-0 bg-white rounded-b-3xl p-4">
          <div className="bg-gray-100 h-full rounded-lg p-4">
            <span className="text-2xl text-gray-900">
              <h1 className="text-center">
                <span className="text-center text-green-800 text-3xl">
                  Invitation!
                </span>
              </h1>
              <Typewriter
                words={[
                  template,
                  "We look forward to sharing this special moment with you.",
                ]}
                loop={false}
                cursor
                cursorStyle="_"
                typeSpeed={30}
                deleteSpeed={10}
                delaySpeed={8000}
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhoneMockup;
