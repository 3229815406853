import React from "react";
import Navigation from "../layout/clientnav";
import Img from "../assets/livestream.jpeg"

const Livestream = () => {
  return (
    <div className="min-h-screen flex flex-col">
      {/* Navigation Bar */}
      <Navigation />

      {/* Main Content */}
      <div className="flex-grow flex items-center justify-center bg-black">
        <div className="text-center">
          {/* Image */}
          <img
            src={Img}
            alt="Work in Progress"
            className="mx-auto mb-6 w-64 h-64 object-cover rounded-full"
          />
          
          {/* Message */}
          <h1 className="text-3xl font-bold text-gray-300 mb-4">
            Livestream Coming Soon!
          </h1>
          <p className="text-lg text-gray-400">
            We're working hard to bring this feature to you. Stay tuned for an exciting livestream experience!
          </p>

          {/* Optional: Add a Call-to-Action */}
          <div className="mt-8">
            <button
              className="px-6 py-2 bg-custom-green text-black rounded-md hover:bg-green-900 transition duration-300"
              onClick={() => alert('Stay tuned for updates!')}
            >
              Notify Me When Available
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Livestream;
