import React, { useEffect, useState } from "react";
import VideoCard from "../components/VideoCard";
import VideoModal from "../components/VideoModal";
import Navigation from "../layout/clientnav";
import { useParams } from "react-router-dom";
import { getMediaByEvent } from "../services/api";

const VideosPage = () => {
  const { eventid } = useParams();
  const [videos, setVideos] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await getMediaByEvent(eventid);
        const media = response.data;

        const videoFiles = media.filter(item => item.type === "video");
        setVideos(videoFiles);
      } catch (error) {
        console.error("Error fetching videos:", error);
      }
    };

    fetchVideos();
  }, [eventid]);

  return (
    <div className="min-h-screen bg-black p-5">
      <Navigation />
      <h1 className="text-3xl font-bold text-center mb-5 mt-16 text-white">Videos</h1>
      {/* {console.log(videos)} */}
      {videos.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          {videos.map((video, index) => (
            <VideoCard
              key={index}
              video={video}
              onClick={() => setSelectedVideo(video)}
            />
          ))}
        </div>
      ) : (
        <div className="flex justify-center items-center h-full">
          <h2 className="text-2xl font-bold text-white">
            There are no videos yet.
          </h2>
        </div>
      )}
      {selectedVideo && (
        <VideoModal
          video={selectedVideo}
          onClose={() => setSelectedVideo(null)}
          videos={videos}
        />
      )}
    </div>
  );
};

export default VideosPage;
