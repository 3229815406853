// src/ImageCard.js
import React from "react";

//****IMPORTANT*******Image card with animation property ****IMPORTANT*******//////////////////////

// const ImageCard = ({ src, alt }) => {
//   return (
//     <div className="rounded-lg shadow-lg overflow-hidden transform transition-transform hover:scale-110">
//       <img src={src} alt={alt} className="w-full h-auto object-cover" />
//     </div>
//   );
// };

const ImageCard = ({ src, alt, onClick }) => {
  return (
    <div className="cursor-pointer" onClick={onClick}>
      <img src={src} alt={alt} className="w-full h-auto rounded-md shadow-md" />
    </div>
  );
};

export default ImageCard;
