import React from "react";
import { FaTimes, FaDownload } from "react-icons/fa";

const ImageModal = ({ src, onClose }) => {
  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = src;
    link.download = "image.jpg"; // You can customize the file name here
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 p-4">
      <div className="bg-white p-5 rounded-lg shadow-lg relative max-w-full max-h-full overflow-y-auto">
        <button
          className="absolute top-2 right-2 text-2xl text-red-800"
          onClick={onClose}
        >
          <FaTimes />
        </button>
        <div
          className="flex justify-center items-center"
          style={{ maxHeight: "calc(100vh - 10rem)" }}
        >
          {/* {console.log("image", src)} */}
          <img
            src={src}
            alt="Selected"
            className="max-w-full max-h-full rounded"
          />
        </div>
        <div className="flex justify-center mt-3">
          <button
            className="px-4 py-2 bg-blue-500 text-white rounded flex items-center"
            onClick={handleDownload}
          >
            <FaDownload className="mr-2" />
            Download
          </button>
        </div>
      </div>
    </div>
  );
};

export default ImageModal;
