import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ImageCard from "../components/ImageCard";
import { getMediaByEvent } from "../services/api";
import Navigation from "../layout/clientnav";

const PreEventPage = () => {
  const { eventid } = useParams();
  const [images, setImages] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);
  const [shuffledImages, setShuffledImages] = useState([]);

  useEffect(() => {
    const fetchMedia = async () => {
      try {
        const response = await getMediaByEvent(eventid);
        const media = response.data;

        const images = media.filter(item => item.type === "image").map(item => item.url);
        const audios = media.filter(item => item.type === "audio").map(item => item.url);

        setImages(images);
        setAudioTracks(audios);
      } catch (error) {
        console.error("Error fetching media:", error);
      }
    };

    fetchMedia();
  }, [eventid]);

  useEffect(() => {
    if (audioTracks.length > 0) {
      const audioElement = document.getElementById("background-audio");

      const changeAudio = () => {
        const randomAudio = audioTracks[Math.floor(Math.random() * audioTracks.length)];
        audioElement.src = randomAudio;
        audioElement.play().catch(error => {
          console.error("Error playing audio:", error);
        });
      };

      changeAudio();
      const interval = setInterval(changeAudio, 180000); // 3 minutes
      return () => clearInterval(interval);
    }
  }, [audioTracks]);

  useEffect(() => {
    const shuffleGallery = () => {
      const shuffledImages = [...images].sort(() => 0.5 - Math.random());
      const numImagesToShow = window.innerWidth < 769 ? 1 : 3;
      setShuffledImages(shuffledImages.slice(0, numImagesToShow));
    };

    const handleResize = () => shuffleGallery();

    window.addEventListener("resize", handleResize);
    shuffleGallery();

    const interval = setInterval(shuffleGallery, 5000);
    return () => {
      clearInterval(interval);
      window.removeEventListener("resize", handleResize);
    };
  }, [images]);

  return (
    <div className="bg-black text-white flex flex-col items-center justify-center min-h-screen relative pt-5">
      <Navigation />
      {audioTracks.length > 0 && (
        <audio controls autoPlay loop id="background-audio" className="my-12">
          <source src={audioTracks[0]} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      )}
      {images.length > 0 ? (
        <>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 p-5">
        {shuffledImages.map((src, index) => (
          <ImageCard key={index} src={src} alt={`Prewedding Photo ${index + 1}`} />
        ))}
      </div>
      </>
      ) : (
        <div className="flex justify-center items-center h-full">
          <h2 className="text-2xl font-bold text-white">
            There are no pre-event images or music yet.
          </h2>
        </div>
      )}
    </div>
  );
};

export default PreEventPage;


