import React, { useState } from 'react';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { uploadMedia } from '../services/api';
import useStore from '../store/event-store';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from "../components/loader";
import bgImg from "../assets/baloons.jpg"
import { IoMdClose } from "react-icons/io";

const PreEventsUploads = () => {
  const [musicFiles, setMusicFiles] = useState([]);
  const [imageFiles, setImageFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const navigate = useNavigate()

  const {eventid} = useParams()
  const hostPhoneNumber = useStore((state) => state.hostphonenumber);
  
  const handleMusicFilesChange = (e) => {
    if (e.target.files.length > 5) {
      toast.error("You can upload a maximum of 5 music files.");
      return;
    }
    setMusicFiles(Array.from(e.target.files));
  };

  const handleImageFilesChange = (e) => {
    if (e.target.files.length > 5) {
      toast.error("You can upload a maximum of 5 image files.");
      return;
    }
    setImageFiles(Array.from(e.target.files));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("phone", hostPhoneNumber);
    formData.append("eventid", eventid);
    musicFiles.forEach((file) => formData.append("files", file));
    imageFiles.forEach((file) => formData.append("files", file));

    try {
      setLoading(true);
      setMessage("Uploading media...");

      const response = await uploadMedia(formData);
      if(response.data){
        setLoading(false);
        toast.success("Pre-events files uploaded successfully!");
        setTimeout(()=>{

          navigate(-1)
        },2000)
      }
    } catch (error) {
      setLoading(false);
      console.error('Error uploading pre-event files:', error);
      toast.error("Error creating pre-event files");
    }
  };

  return (
    <div className="relative h-[89vh] md:h-[92.5vh] flex flex-col items-center justify-center px-5 bg-cover bg-center" style={{ backgroundImage: `url(${bgImg})` }}>
    <div className="absolute inset-0 bg-darkOlive bg-opacity-50 z-0"></div>
    {loading ? (
      <Loader>{message}</Loader>
    ) : (
      <form
        className="w-full max-w-lg mx-auto bg-white shadow-lg rounded px-8 pt-6 pb-8 mb-4 z-10 relative"
        onSubmit={handleSubmit}
      >
        <IoMdClose size={25} className='absolute right-4 cursor-pointer text-darkOlive top-1' onClick={()=>navigate(-1)} />
        {/* Description */}
        <div className="mb-8 text-center">
          <h2 className="text-2xl font-semibold text-gray-800">Upload Pre-Event Files</h2>
          <p className="text-gray-600 mt-2 text-start">
            You can upload a maximum of 5 music files and 5 images at a time.
          </p>
        </div>
  
        <div className="mb-6">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Music Files (max 5):
          </label>
          <input
            type="file"
            multiple
            accept="audio/*"
            onChange={handleMusicFilesChange}
            className="shadow-md appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
  
        <div className="mb-6">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Image Files (max 5):
          </label>
          <input
            type="file"
            multiple
            accept="image/*"
            onChange={handleImageFilesChange}
            className="shadow-md appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
  
        <button
          type="submit"
          className="bg-custom-gradient-new hover:bg-custom-gradient text-white font-bold py-3 px-6 rounded focus:outline-none focus:shadow-outline shadow-md"
        >
          Upload Pre-Event Files
        </button>
      </form>
    )}
    <ToastContainer />
  </div>
  

  );
};

export default PreEventsUploads;

