import React, { useState } from "react";
import Sidenav from "../components/sidenav";
import { Outlet } from "react-router-dom";
import NavBar from "../components/navbar";
import { FaTimes } from "react-icons/fa";

const Layout = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidenav = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="min-h-screen flex flex-col md:flex-row">
      {/* Sidenav for larger screens */}
      <div className="hidden md:block md:w-44 bg-custom-gradient">
        <Sidenav />
      </div>

      {/* Mobile sidenav */}
      <div
        className={`fixed inset-0 z-50 bg-gray-800 bg-opacity-50 md:hidden ${
          isOpen ? "block" : "hidden"
        }`}
      >
        <div className="relative w-44 bg-custom-gradient p-4 h-full">
          {/* Close Icon at the top right */}
          <button
            className="absolute top-2 right-2 text-white text-2xl"
            onClick={toggleSidenav}
          >
            <FaTimes />
          </button>

          {/* Sidenav content */}
          <Sidenav />
        </div>
      </div>

      {/* Main content */}
      <div className="flex-grow pt-0 pb-4 bg-[#F0F2DA]">
        {/* <div className="fixed top-0 z-50 bg-white shadow-md w-full"> */}
        <NavBar onClick={toggleSidenav} />
        {/* </div> */}
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
