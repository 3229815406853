import React from "react";
import { useNavigate } from "react-router-dom";

const ErrorPage = () => {
    const navigate = useNavigate()
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-6">
      <div className="bg-white shadow-lg rounded-lg p-8 md:p-16 max-w-lg text-center">
        <h1 className="text-6xl font-bold text-[#E63946] mb-4">404</h1>
        <h2 className="text-3xl font-semibold text-gray-800 mb-4">
          Oops! Page not found.
        </h2>
        <p className="text-gray-600 mb-8">
          Sorry, the page you are looking for doesn't exist. If you think this
          is a mistake, please contact support.
        </p>
        <button
         onClick={()=>navigate(-1)}
          className="bg-[#1D3557] text-white px-6 py-3 rounded-full hover:bg-[#457b9d] transition duration-300"
        >
          Go Back Home
        </button>
      </div>
    </div>
  );
};

export default ErrorPage;
