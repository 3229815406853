import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useStore from "../store/event-store";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createEvent } from "../services/api";
import Loader from "./loader";
import { IoMdClose } from "react-icons/io";


const eventTemplates = {
    wedding: {
      name: "Wedding",
      fields: [
        { name: "groomFamily", label: "Groom's Family" },
        { name: "brideFamily", label: "Bride's Family" },
        { name: "groom", label: "Groom's Name" },
        { name: "bride", label: "Bride's Name" },
        { name: "location", label: "Event Location" },
        { name: "time", label: "Event Time" },
        { name: "date", label: "Event Date", type: "date" },
      ],
    },
    birthday: {
      name: "Birthday",
      fields: [
        { name: "name", label: "Birthday Person's Name" },
        { name: "time", label: "Event Time" },
        { name: "location", label: "Event Location" },
        { name: "date", label: "Event Date", type: "date" },
      ],
    },
    anniversary: {
      name: "Anniversary",
      fields: [
        { name: "years", label: "Number of Years" },
        { name: "names", label: "Names of Couple" },
        { name: "time", label: "Event Time" },
        { name: "location", label: "Event Location" },
        { name: "date", label: "Event Date", type: "date" },
      ],
    },
    festival: {
      name: "Festival",
      fields: [
        { name: "festivalName", label: "Festival Name" },
        { name: "time", label: "Event Time" },
        { name: "location", label: "Event Location" },
        { name: "date", label: "Event Date", type: "date" },
      ],
    },
  };


const CreateEventForm = ({isModalOpen, onClose, onRefresh}) => {
    const [eventType, setEventType] = useState('');
    const [formData, setFormData] = useState({ phone: '', eventData: {} });
    const [files, setFiles] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [preview, setPreview] = useState(null);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
  
    const user = useStore((state) => state.user);
    const navigate = useNavigate();
  
    
  
    const handleEventTypeChange = (e) => {
      setEventType(e.target.value);
      setFormData({ ...formData, eventData: {} });
    };
  
    const handleEventDataChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        eventData: {
          ...formData.eventData,
          [name]: value,
        },
      });
    };
  
    const handleFileChange = (e) => {
      setFiles(e.target.files);
      const file = e.target.files[0]
      if (file) {
        setSelectedFile(file);
  
        // If the file is an image, create a preview URL
        if (file.type.startsWith("image/")) {
          const reader = new FileReader();
          reader.onloadend = () => {
            setPreview(reader.result);
          };
          reader.readAsDataURL(file);
        } else {
          setPreview(null); // Reset preview for non-image files
        }
      }
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
  
      const data = new FormData();
      data.append('phone', user.phone);
      data.append('eventType', eventType);
      data.append('eventData', JSON.stringify(formData.eventData));
      for (let i = 0; i < files.length; i++) {
        data.append('files', files[i]);
      }
  
      try {
        setLoading(true);
        setMessage('Creating Event...');
  
        const response = await createEvent(data); 
        if (response.data) {
          setLoading(false);
          toast.success('Event created successfully!');
          setTimeout(()=>{
            onClose();
            onRefresh(); 
          },1000)
          // navigate('/events');
        }
      } catch (error) {
        setLoading(false);
        navigate('/registration'); 
        toast.error('Error creating event');
      }
    };
  
    const renderEventFields = () => {
      if (!eventType) return null;
      return eventTemplates[eventType].fields.map((field) => (
        <div className="mb-4" key={field.name}>
          <label className="block text-gray-700 text-sm font-bold mb-2">
            {field.label}:
            {field.type === 'textarea' ? (
              <textarea
                name={field.name}
                value={formData.eventData[field.name] || ''}
                onChange={handleEventDataChange}
                required
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            ) : (
              <input
                type={field.type || 'text'}
                name={field.name}
                value={formData.eventData[field.name] || ''}
                onChange={handleEventDataChange}
                required
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            )}
          </label>
        </div>
      ));
    };

    if(!isModalOpen) return null;
  
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center px-4 md:px-0">
        {loading ? (
        <Loader>{message}</Loader>
      ) : (
        <form
          className="bg-white shadow-md rounded-lg p-8 w-[90vw] md:w-full max-w-4xl my-auto py-auto absolute overflow-y-scroll max-h-[85vh]"
          onSubmit={handleSubmit}
        >
          <IoMdClose size={25} className='absolute right-4 cursor-pointer text-darkOlive top-3' onClick={onClose} />
          <h2 className="text-2xl font-bold text-center text-darkOlive mb-6">
            Create an Event
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
            {/* Event Type */}
            <div>
              <label className="block text-gray-700 font-medium mb-2">
                Event Type
              </label>
              <select
                name="eventType"
                value={eventType}
                onChange={handleEventTypeChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-darkOlive bg-transparent"
              >
                <option value="">Select an event type</option>
                <option value="wedding">Wedding</option>
                <option value="birthday">Birthday</option>
                <option value="anniversary">Anniversary</option>
                <option value="festival">Festival</option>
              </select>
            </div>
  
            {/* Dynamic Event Fields */}
            {renderEventFields()}
          </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mt-3">
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2">
                Maximum Seats (optional):
                <input
                    type="number"
                    name="maxSeats"
                    value={formData.eventData.maxSeats || ""}
                    onChange={handleEventDataChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </label>
          </div>
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2">
                Color (optional):
                    <input
                    type="text"
                    name="color"
                    value={formData.eventData.color || ""}
                    onChange={handleEventDataChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </label>
          </div>
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2">
                Theme (optional):
                    <input
                    type="text"
                    name="theme"
                    value={formData.eventData.theme || ""}
                    onChange={handleEventDataChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </label>
          </div>
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2">
                Event Name (optional):
                    <input
                    type="text"
                    name="eventname"
                    value={formData.eventData.eventname || ""}
                    onChange={handleEventDataChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </label>
          </div>
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2">
                Contact Person (optional):
                    <input
                    type="text"
                    name="contactperson"
                    value={formData.eventData.contactperson || ""}
                    onChange={handleEventDataChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </label>
          </div>
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2">
                Description (optional):
                    <textarea
                    name="description"
                    value={formData.eventData.description || ""}
                    onChange={handleEventDataChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </label>
          </div>
        </div>

          {/* File Upload */}

          <div className="mt-6">
            <label className="block text-gray-700 font-medium mb-2">
            Cover Photo for Event (Just 1 image):
            </label>
            <div className="border-2 border-dashed border-gray-300 p-4 rounded-lg text-center">
              <input
                type="file"
                name="files"
                accept="image/*"
                onChange={handleFileChange}
                className="hidden"
                id="fileInput"
              />
              <label
                htmlFor="fileInput"
                className="text-darkOlive font-medium cursor-pointer"
              >
                Select files or drag and drop
              </label>
              <p className="text-sm text-gray-500">JPG or PNG</p>
              {selectedFile && (
                <div className="mt-4">
                  {preview ? (
                    <img
                      src={preview}
                      alt="Preview"
                      className="max-w-full h-auto object-cover rounded-lg"
                      style={{ maxHeight: "150px" }}
                    />
                  ) : (
                    <p className="text-gray-700 font-medium">{selectedFile.name}</p>
                  )}
                </div>
              )}
            </div>
          </div>

          {/*  Pls Do Not Delete Its Very Important */} {/*  Pls Do Not Delete Its Very Important */}

          {/* <div className="mt-6">
            <label className="block text-gray-700 font-medium mb-2">
              Upload Files
            </label>
            <div className="border-2 border-dashed border-gray-300 p-4 rounded-lg text-center">
              <input
                type="file"
                name="files"
                accept="image/*,video/*"
                onChange={handleFileChange}
                className="hidden"
                id="fileInput"
                multiple
              />
              <label
                htmlFor="fileInput"
                className="text-darkOlive font-medium cursor-pointer"
              >
                Select files or drag and drop
              </label>
              <p className="text-sm text-gray-500">JPG, PNG, or MP4 file</p>
            </div>
          </div> */}
  
          {/* Loading & Message */}
          {/* {loading && <p className="text-darkOlive font-bold mt-4">{message}</p>} */}
  
          {/* Submit Button */}
          <div className="mt-6 text-center">
            <button
              type="submit"
              className="bg-darkOlive text-white font-semibold px-6 py-3 rounded-lg hover:bg-green-800 transition-all"
            >
              Create Event
            </button>
          </div>
        </form>
         )}
        <ToastContainer />
        
      </div>
    );
  };
  
  export default CreateEventForm;



