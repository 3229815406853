import React, { useEffect, useState } from "react";
import useStore from "../store/event-store";
import { getAllHostsEvents } from "../services/api";
import CardSectionAllEvents from "../components/cardsectionallevents";
import EventsDynamicTable from "../components/eventsdynamictable";

const EventsDashboard = () => {

  const hostphonenumber = useStore((state) => state.hostphonenumber);
  const user = useStore((state) => state.user);
  const [events, setEvents] = useState([]);
  const [refresh, setRefresh] = useState(false); 
  

  useEffect(() => {
    const getAllEvents = async () => {
      try {
        const res = await getAllHostsEvents(hostphonenumber);

        setEvents(res.data);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };
    if (hostphonenumber) {
      getAllEvents();
    }
  }, [hostphonenumber, refresh]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleRefresh = () => {
    setRefresh(prev => !prev); 
  };
  
  return(
    
    <div className="px-8 "> 
      <p className="text-darkOlive font-bold text-3xl pb-5 pt-5">Welcome, {user? user.name : "Host"}</p>
      <CardSectionAllEvents data={events}/>
      <div className="pt-2 md:pt-[55px]">
        <p className="text-darkOlive font-bold text-3xl pb-5">Events List</p>
        <EventsDynamicTable data={events} onRefresh={handleRefresh} />
      </div>
      
    </div>
  )
}
  
export default EventsDashboard;

