// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// const GlassIconDiv = ({ icon, fontSize = "30px" }) => {
//   return (
//     <div
//       className="w-14 h-14 sm:w-16 sm:h-16 rounded-xl flex items-center justify-center"
//       style={{
//         cursor: "pointer",
//         background: "rgba(255, 255, 255, 0.5)",
//         backdropFilter: "blur(10px)",
//         WebkitBackdropFilter: "blur(10px)",
//       }}
//     >
//       <FontAwesomeIcon icon={icon} style={{ fontSize: fontSize }} />
//     </div>
//   );
// };

// export default GlassIconDiv;

// The above is without the information tooltip

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const GlassIconDiv = ({ icon, fontSize = "30px", information = "" }) => {
  return (
    <div
      className="relative group w-14 h-14 sm:w-16 sm:h-16 rounded-xl flex items-center justify-center"
      style={{
        cursor: "pointer",
        background: "rgba(255, 255, 255, 0.5)",
        backdropFilter: "blur(10px)",
        WebkitBackdropFilter: "blur(10px)",
      }}
    >
      <FontAwesomeIcon icon={icon} style={{ fontSize: fontSize }} />
      {information && (
        <div className="absolute left-1/2 transform -translate-x-1/2 mt-2 hidden group-hover:block">
          <div className="relative bg-gray-700 text-white text-sm  rounded-lg p-5">
            {information}
            <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 -mb-2 w-0 h-0 border-l-8 border-l-transparent border-r-8 border-r-transparent border-b-8 border-b-gray-700"></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GlassIconDiv;
