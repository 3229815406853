// import React from "react";
// import Countdown from "react-countdown";
// import Counter from "./countter-new";

// const CounterComponent = ({ targetDate }) => {
//   const renderer = ({ days, hours, minutes, seconds, completed }) => {
//     if (completed) {
//       return <span className="text-red-500">Alhamdulilah!!! We are Halal</span>;
//     } else {
//       const now = new Date();
//       const target = new Date(targetDate);
//       const timeDiff = target - now;
//       const oneMonthInMilliseconds = 30 * 24 * 60 * 60 * 1000;

//       if (timeDiff <= oneMonthInMilliseconds) {
//         // Render days, hours, minutes, seconds in the last month
//         return (
//           <Counter
//             months={0}
//             minutes={minutes}
//             days={days}
//             hours={hours}
//             seconds={seconds}
//           />
//         );
//       } else {
//         // Render months, days, hours, minutes, seconds
//         const months = Math.floor(days / 30);
//         const remainingDays = days % 30;

//         return (
//           <div>
//             <Counter
//               months={months}
//               minutes={minutes}
//               days={remainingDays}
//               hours={hours}
//               seconds={seconds}
//             />
//           </div>
//         );
//       }
//     }
//   };

//   return (
//     <div className="bg-white p-7  shadow-md mb-3 text-center border border-[#d3e537]">
//       <h2 className="text-xl font-bold mb-2 text-black text-center">
//         Countdown
//       </h2>
//       <div className="mb-2 text-black">
//         <Countdown date={targetDate} renderer={renderer} />
//       </div>
//     </div>
//   );
// };

// export default CounterComponent;

import React, { useState, useEffect } from "react";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import AnimatedText from "./animated-text/AnimatedText";
import GlassIconDiv from "./glass-icon";

const CounterComponent = ({ targetDate }) => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(targetDate));

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft(targetDate));
    }, 1000);

    return () => clearInterval(timer);
  }, [targetDate]);

  function calculateTimeLeft(targetDate) {
    const difference = new Date(targetDate) - new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  }

  return (
    <div className="p-4 bg-white rounded-lg text-center">
      <div className="w-full flex justify-center">
        <GlassIconDiv icon={faClock} fontSize="50px" />
      </div>

      <AnimatedText>
        <h2 className="text-xl font-semibold">Countdown</h2>
      </AnimatedText>
      <div className="text-lg">
        {Object.keys(timeLeft).length > 0 ? (
          <div>
            {/* {timeLeft.days}d {timeLeft.hours}h {timeLeft.minutes}m{" "}
            {timeLeft.seconds}s */}
            {timeLeft.days}days to go
          </div>
        ) : (
          <div>
            Event has started!{" "}
            <span className="text-6xl text-green-500">&#10003;</span>
          </div>
        )}
      </div>
    </div>
  );
};
export default CounterComponent;
