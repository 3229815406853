import React, { useState } from "react";
import { FaTimes, FaDownload } from "react-icons/fa";

const VideoModal = ({ video, onClose, videos }) => {
  const [currentVideoIndex] = useState(videos.indexOf(video));

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = videos[currentVideoIndex].url;
    link.download = "video.mp4"; // You can customize the file name here
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const currentVideo = videos[currentVideoIndex];

  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 p-4">
      <div className="bg-white p-5 rounded-lg shadow-lg relative max-w-full max-h-full overflow-y-auto">
        <button
          className="absolute top-2 right-2 text-red-500 text-2xl"
          onClick={onClose}
        >
          <FaTimes />
        </button>
        <button
          className="absolute bottom-2 right-2 text-green-500 text-2xl"
          onClick={handleDownload}
        >
          <FaDownload />
        </button>
        <div
          className="flex justify-center items-center"
          style={{ maxHeight: "calc(100vh - 10rem)" }}
        >
          <video
            className="max-w-full max-h-full rounded"
            src={currentVideo.url}
            controls
            autoPlay
          />
        </div>
        <p className="mt-3">{currentVideo.description}</p>
      </div>
    </div>
  );
};

export default VideoModal;
