// import React, { useEffect, useState } from "react";
// import { getEvent, getUser, initializeTransaction } from "../services/api";
// import { useLocation } from "react-router-dom";

// const DonationComponent = ({ closeDonationBox }) => {
//   const [hostAccountNumber, setHostAccountNumber] = useState("");
//   const [hostBankName, setHostBankName] = useState("");
//   const [hostEmail, setHostEmail] = useState("");
//   const [error, setError] = useState("");

//   const [formData, setFormData] = useState({
//     phoneNumber: "",
//     email: "",
//     amount: "",
//     event: "",
//   });

//   const { pathname } = useLocation();
//   const eventid = pathname.split("/").pop();

//   // Get event and host details from backend and set them in state
//   const getEventDetails = async (eventId) => {
//     try {
//       const response = await getEvent(eventId);
//       const host = await getUser(response.data?.user);

//       setHostAccountNumber(host.data?.hostAccountNumber || "");
//       setHostBankName(host.data?.hostBankName || "");
//       setHostEmail(host.data?.email || "");
//       setFormData((prevData) => ({
//         ...prevData,
//         event: eventId,
//       }));
//     } catch (error) {
//       console.error("Error fetching event details:", error);
//       setError("Failed to load event details. Please try again.");
//     }
//   };

//   useEffect(() => {
//     if (eventid) {
//       getEventDetails(eventid);
//       // setEventId(eventid);
//     }
//   }, [eventid]);

//   const handleChange = (e) => {
//     const { name, value } = e.target;

//     // Automatically convert amount to kobo
//     if (name === "amount") {
//       const amountInKobo = value * 100;
//       setFormData((prevData) => ({
//         ...prevData,
//         [name]: amountInKobo,
//       }));
//     } else {
//       setFormData((prevData) => ({
//         ...prevData,
//         [name]: value,
//       }));
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setError(""); // Clear any previous errors

//     // Validate inputs before attempting the transaction
//     if (!formData.email || !formData.phoneNumber || !formData.amount) {
//       setError("Please fill in all required fields.");
//       return;
//     }

//     console.log("Submitting transaction with formData:", formData);

//     try {
//       const response = await initializeTransaction({
//         email: formData.email,
//         amount: formData.amount, // amount in kobo
//         hostAccountNumber,
//         hostBankName,
//         event: formData.event || eventid,
//         phoneNumber: formData.phoneNumber,
//         hostEmail,
//       });

//       const data = response.data;
//       console.log("Transaction initialized successfully:", data);

//       // Redirect to Paystack payment URL if transaction is initialized successfully
//       if (data.authorization_url) {
//         window.location.href = data.authorization_url;
//       } else {
//         setError("Failed to get payment URL. Please try again.");
//       }
//     } catch (error) {
//       // Handle errors appropriately
//       console.error("Transaction error:", error);
//       const errorMessage =
//         error.response?.data?.error ||
//         "An error occurred while processing your donation. Please try again.";
//       setError(errorMessage);
//     }
//   };

//   return (
//     <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
//       <div className="bg-white p-4 rounded-lg shadow-lg sm:w-3/4 md:w-1/3 lg:w-1/4 relative z-50">
//         <div className="flex justify-end">
//           <button
//             onClick={closeDonationBox}
//             className="px-4 py-2 bg-red-500 text-white hover:bg-red-700 transition rounded"
//           >
//             Close
//           </button>
//         </div>
//         <h2 className="text-xl font-semibold text-center">Make a Donation</h2>
//         <form onSubmit={handleSubmit}>
//           <div className="mb-4">
//             <label className="block text-gray-700">phoneNumber:</label>
//             <input
//               type="text"
//               name="phoneNumber"
//               value={formData.phoneNumber}
//               onChange={handleChange}
//               className="mt-1 p-2 border rounded w-full"
//               autoComplete="phone"
//               required
//             />
//           </div>
//           <div className="mb-4">
//             <label className="block text-gray-700">Donor Email:</label>
//             <input
//               type="email"
//               name="email"
//               value={formData.email}
//               onChange={handleChange}
//               placeholder="enter a valid email address"
//               className="mt-1 p-2 border rounded w-full"
//               autoComplete="email"
//               required
//             />
//           </div>
//           <div className="mb-4">
//             <label className="block text-gray-700">Donation Amount (₦):</label>
//             <input
//               type="text"
//               name="amount"
//               value={formData.amount / 100}
//               onChange={handleChange}
//               className="mt-1 p-2 border rounded w-full"
//               required
//             />
//           </div>
//           <button
//             type="submit"
//             className="px-4 py-2 bg-green-600 text-white hover:bg-[#d3e999] transition rounded"
//           >
//             Donate
//           </button>
//           {error && <p style={{ color: "red" }}>{error}</p>}
//         </form>
//       </div>
//     </div>
//   );
// };

// export default DonationComponent;

import React, { useEffect, useState } from "react";
import { getEvent, getUser, initializeTransaction } from "../services/api";
import { useLocation } from "react-router-dom";
import Loader from "./loader";

const DonationComponent = ({ closeDonationBox }) => {
  const [hostAccountNumber, setHostAccountNumber] = useState("");
  const [hostBankName, setHostBankName] = useState("");
  const [hostEmail, setHostEmail] = useState("");
  const [error, setError] = useState("");
  const [subAccount, setSubAccount] = useState("");
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    phoneNumber: "",
    email: "",
    amount: "",
    event: "",
  });

  const { pathname } = useLocation();
  const eventid = pathname.split("/").pop();

  // Get event and host details from backend and set them in state
  const getEventDetails = async (eventId) => {
    try {
      const event = await getEvent(eventId);
      const host = await getUser(event.data?.user);

      // console.log("need host id", host.data?._id);

      setHostAccountNumber(host.data?.hostAccountNumber || "");
      setHostBankName(host.data?.hostBankName || "");
      setHostEmail(host.data?.email || "");
      setSubAccount(host.data?.subaccount_code);
      setFormData((prevData) => ({
        ...prevData,
        event: event.data._id,
      }));
    } catch (error) {
      console.error("Error fetching event details:", error);
      setError("Failed to load event details. Please try again.");
    }
  };

  useEffect(() => {
    if (eventid) {
      getEventDetails(eventid);
    }
  }, [eventid]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Automatically convert amount to kobo
    if (name === "amount") {
      const amountInKobo = value * 100;
      setFormData((prevData) => ({
        ...prevData,
        [name]: amountInKobo,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(""); // Clear any previous errors

    // Validate inputs before attempting the transaction
    if (!formData.email || !formData.phoneNumber || !formData.amount) {
      setError("Please fill in all required fields.");
      return;
    }

    try {
      // Initialize the transaction
      setLoading(true);
      const response = await initializeTransaction({
        email: formData.email,
        amount: formData.amount, // amount in kobo
        hostAccountNumber,
        hostBankName,
        event: formData.event,
        phoneNumber: formData.phoneNumber,
        hostEmail,
        hostSubAccount: subAccount,
      });

      const data = response.data;

      // Redirect to Paystack payment URL if transaction is initialized successfully
      if (data.authorizationUrl) {
        setLoading(false);
        window.location.href = data.authorizationUrl;
      } else {
        setError("Failed to get payment URL. Please try again.");
      }
    } catch (error) {
      // Handle errors appropriately
      setLoading(false);
      const errorMessage =
        error.response?.data?.error ||
        "An error occurred while processing your donation. Please try again.";
      setError(errorMessage);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-4 rounded-lg shadow-lg sm:w-3/4 md:w-1/3 lg:w-1/4 relative z-50">
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="flex justify-end">
              <button
                onClick={closeDonationBox}
                className="px-4 py-2 bg-red-500 text-white hover:bg-red-700 transition rounded"
              >
                Close
              </button>
            </div>
            <h2 className="text-xl font-semibold text-center">
              Make a Donation
            </h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-gray-700">Phone Number:</label>
                <input
                  type="text"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  className="mt-1 p-2 border rounded w-full"
                  autoComplete="phone"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">Donor Email:</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Enter a valid email address"
                  className="mt-1 p-2 border rounded w-full"
                  autoComplete="email"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">
                  Donation Amount (₦):
                </label>
                <input
                  type="text"
                  name="amount"
                  value={formData.amount / 100}
                  onChange={handleChange}
                  className="mt-1 p-2 border rounded w-full"
                  required
                />
              </div>
              <button
                type="submit"
                className="px-4 py-2 bg-green-600 text-white hover:bg-[#d3e999] transition rounded"
              >
                Donate
              </button>
              {error && <p style={{ color: "red" }}>{error}</p>}
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default DonationComponent;
