import React, { useEffect, useState } from "react";
import ImageCard from "../components/ImageCard";
import ImageModal from "../components/ImageModal";
import Navigation from "../layout/clientnav";
import { useParams } from "react-router-dom";
import { getMediaByEvent } from "../services/api";
import "react-toastify/dist/ReactToastify.css";

const itemsPerPage = 6;

const GalleryPage = () => {
  const { eventid } = useParams();
  const [images, setImages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedImage, setSelectedImage] = useState(null);
  // const [loading, setLoading] = useState(false);
  // const [message, setMessage] = useState("");

  useEffect(() => {
    const fetchImages = async () => {
      try {
        // setLoading(true);
        // setMessage("Fetching media...");
        const response = await getMediaByEvent(eventid);
        if(response.data){
          // setLoading(false);

          const media = response.data;
          const imageFiles = media.filter(item => item.type === "image");
          setImages(imageFiles);
        }
        
      } catch (error) {
        // setLoading(false);
        console.error("Error fetching images:", error);
      }
    };

    fetchImages();
  }, [eventid]);

  const totalPages = Math.ceil(images.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentImages = images.slice(startIndex, startIndex + itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="min-h-screen bg-black p-5">
      <Navigation />
      <h1 className="text-3xl font-bold text-center mb-5 mt-16">Gallery</h1>
      {/* {console.log(images)} */}
      
      {images.length > 0 ? (
        <>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {currentImages.map((image, index) => (
              <ImageCard
                key={index}
                src={image.url}
                alt={`Image ${index + 1}`}
                onClick={() => setSelectedImage(image.url)}
              />
            ))}
          </div>
          <div className="flex justify-center mt-32">
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index}
                className={`mx-1 px-3 py-1 rounded ${
                  currentPage === index + 1
                    ? "bg-custom-green text-white"
                    : "bg-gray-300"
                }`}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </>
      ) : (
        <div className="flex justify-center items-center h-full">
          <h2 className="text-2xl font-bold text-white">
            There are no images yet.
          </h2>
        </div>
      )}
      {selectedImage && (
        <ImageModal
          src={selectedImage}
          onClose={() => setSelectedImage(null)}
        />
      )}
    </div>
  );
};

export default GalleryPage;

