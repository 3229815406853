import React from "react";

const TooltipButton = ({
  btntext,
  information,
  btncolor = "bg-blue-500",
  onClick,
}) => {
  return (
    <div className="relative group">
      <button
        className={`${btncolor} text-black px-6 py-3 rounded-lg `}
        onClick={onClick}
      >
        {btntext}
      </button>
      <div className="absolute left-1/2 transform -translate-x-1/2 mt-2 hidden group-hover:block">
        <div className="relative bg-gray-700 text-white text-sm  rounded-lg p-5">
          {information}
          <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 -mb-2 w-0 h-0 border-l-8 border-l-transparent border-r-8 border-r-transparent border-b-8 border-b-gray-700"></div>
        </div>
      </div>
    </div>
  );
};

export default TooltipButton;
