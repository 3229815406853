import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import useStore from "../store/event-store";

const Navigation = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const guest = useStore((state) => state.guestname);
  const navigate = useNavigate();

  const location = useLocation();
  const eventId = location.pathname.split("/").pop(); // Note i didnt use useParams here because Navigation isnt a route such as /navigation/:eventid

  return (
    <div className="fixed top-0 left-0 w-full p-4">
      <div className="flex justify-between items-center">
        <div
          className="text-white text-lg font-bold cursor-pointer"
          onClick={() => navigate(-1)}
        >
          Back
        </div>
        <div className="hidden lg:flex flex-row gap-3 w-[750px]">
          <div
            className="flex justify-between items-center p-2 rounded-lg"
            style={{
              height: "40px",
              width: "350px",
              backgroundColor: "white",
              borderRadius: "10px",
            }}
          >
            <button
              className="text-gray-800 hover:text-gray-600 transition-colors duration-300"
              onClick={() => {
                navigate(`/guestview/${eventId}`);
              }}
            >
              Home
            </button>
            <button
              className="text-gray-800 hover:text-gray-600 transition-colors duration-300"
              onClick={() => {
                navigate(`/livestream/${eventId}`);
              }}
            >
              Livestream
            </button>
            <button
              className="text-gray-800 hover:text-gray-600 transition-colors duration-300"
              onClick={() => {
                navigate(`/gallery/${eventId}`);
              }}
            >
              Photos
            </button>
            <button
              className="text-gray-800 hover:text-gray-600 transition-colors duration-300"
              onClick={() => {
                navigate(`/videos/${eventId}`);
              }}
            >
              Videos
            </button>
          </div>
          <div
            className="flex justify-between items-center p-2 rounded-lg bg-custom-green"
            style={{
              height: "40px",
              width: "350px",
              // backgroundColor: "white",
              borderRadius: "10px",
            }}
          >
            <button className="text-gray-800 hover:text-gray-600 transition-colors duration-300">
              Welcome
            </button>
            <div className="flex items-center">
              {/* <img
                src="https://avatars.githubusercontent.com/u/113108283?s=400&u=51d83f083330336368c3fc6d6d061a8e33c2986e&v=4" // Replace with the URL of the avatar image
                alt="Avatar"
                className="w-8 h-8 rounded-full mr-2"
              /> */}
              <span className="text-gray-800">{guest ? guest : "Guest"}</span>
            </div>
          </div>
        </div>
        <div className="lg:hidden">
          <button onClick={() => setIsNavOpen(!isNavOpen)}>
            <FontAwesomeIcon icon={faBars} className="text-white text-2xl" />
          </button>
        </div>
      </div>
      {isNavOpen && (
        <div className="lg:hidden mt-2 bg-gray-700 rounded p-2">
          <Link to={`/guestview/${eventId}`} className="block text-white py-2">
            Home
          </Link>
          <Link to={`/livestream/${eventId}`} className="block text-white py-2">
            Livestream
          </Link>
          <Link to={`/gallery/${eventId}`} className="block text-white py-2">
            Gallery
          </Link>
          <Link to={"/login"} className="block text-white py-2">
            Create Event
          </Link>
        </div>
      )}
    </div>
  );
};

export default Navigation;
