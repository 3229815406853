import PropTypes from 'prop-types';
import { PieChart, Pie, Tooltip, Label } from 'recharts'; // Import necessary components from Recharts


// Utility function to format large numbers
// const fShortenNumber = (num) => {
//   return new Intl.NumberFormat('en-US', { notation: 'compact', compactDisplay: 'short' }).format(num);
// };

export default function AppWidgetSummary({ title, total, icon, color = 'primary', className = '', percentage, ...other }) {
  
  const renderChart = () => {
    // Only render the chart if the percentage is provided and is a valid number
    
    if (percentage !== undefined && percentage >= 0) {
      const data = [
        { name: 'Occupied', value: percentage, fill: '#545822' },
        { name: 'Available', value: 100 - percentage, fill: '#FFFFFF' },
      ];
      return (
        <PieChart width={80} height={80}>
          <Pie
            data={data}
            cx={40}
            cy={40}
            innerRadius={20}
            outerRadius={30}
            dataKey="value"
            startAngle={90}
            endAngle={-270}
          >
            <Label
            value={`${Math.round(percentage)}%`}
            position="center"
            fill="#545822" 
            fontSize={12} 
            fontWeight="bold" 
          />
          </Pie>
          <Tooltip />
        </PieChart>
      );
    }
    return null; // Don't render anything if no percentage is provided
  };

  return (
    <div
      className={`flex relative px-2 py-8 rounded-lg shadow-lg justify-around ${className}`}
      style={{ backgroundColor: other.style?.backgroundColor || 'white' }}
      {...other}
    >
      <div className='flex flex-row'>
        {icon && <div className="w-16 h-16">{icon}</div>}

        <div className="ml-4 flex flex-col justify-center">
          <p className="text-2xl font-bold text-gray-800">{total}</p>
          <p className="text-xl text-gray-700">{title}</p>
        </div>
      </div>

      <div>
        {renderChart() ? renderChart() : <div className='w-full h-full'/> } 
      </div>
      
    </div>
  );
}

AppWidgetSummary.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  className: PropTypes.string,
  title: PropTypes.string,
  total: PropTypes.number || PropTypes.string,
  percentage: PropTypes.number, 
};




