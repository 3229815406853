import AppWidgetSummary from "./appwidgetsummarycard";
import icon1 from "../assets/icons/ic_glass_bag.png"
import icon2 from "../assets/icons/ic_glass_buy.png"
import icon3 from "../assets/icons/ic_glass_users.png"
import icon4 from "../assets/icons/ic_glass_message.png"

const CardSectionAllEvents = ({data}) => {

  const cards = [
    {
      icon:icon1,
      title: "Total Events",
      total: data.length,
      color: "from-blue-600 to-blue-400 shadow-blue-500/40",
      bgColor:"#C7CAAA",
    },
    {
      icon:icon2,
      title: "Subscriptions",
      total: 0,
      color: "from-pink-600 to-pink-400 shadow-pink-500/40",
      bgColor:"#F5E7D8",
    },
    {
      icon:icon3,
      title: "Notifications",
      total: 0,
      color: "from-green-600 to-green-400 shadow-green-500/40",
      bgColor:"#FFF1C9",
    },
    {
      icon: icon4,
      title: "Total Donations",
      total: 0,
      color: "from-orange-600 to-orange-400 shadow-orange-500/40",
      bgColor:"#E8F5E9",
    },
  ];

  return (
    <div className="mt-12">
      <div className="mb-12 grid gap-y-10 gap-x-6 sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-4">
        {cards.map((card, index) => (
          <AppWidgetSummary
            title={card.title}
            total={card.total}
            color={card.color}
            icon={<img alt="icon" src={`${card.icon}`} />}
            style={{ backgroundColor: `${card.bgColor}` }}
          />
        ))}
      </div>
    </div>
  );
};

export default CardSectionAllEvents; 
