import axios from "axios";

// const API_URL = "http://localhost:5000/api/v1";
const API_URL = "https://api.eventaura.com.ng/api/v1";

export const createUser = (userData) =>
  axios.post(`${API_URL}/users`, userData);

export const getUser = (userId) => axios.get(`${API_URL}/users/${userId}`);

export const loginApi = (userData) =>
  axios.post(`${API_URL}/users/login`, userData);

export const createEvent = (eventData) =>
  axios.post(`${API_URL}/events`, eventData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
export const getEvents = () => axios.get(`${API_URL}/events`);
export const getAllHostsEvents = (phone) =>
  axios.get(`${API_URL}/events/allhostevents/${phone}`);
export const getEvent = (eventId) =>
  axios.get(`${API_URL}/events/eventid/${eventId}`);
export const deleteEvent = (eventId) =>
  axios.delete(`${API_URL}/events/eventid/${eventId}`);
export const createReservation = (reservationData) =>
  axios.post(`${API_URL}/reservation`, reservationData);
export const getReservationsByEvent = (eventId) =>
  axios.get(`${API_URL}/reservation/reservation/${eventId}`);
export const getuserReservationsDetailsByEvent = (eventId) =>
  axios.get(`${API_URL}/reservation/reservationswithdetails/${eventId}`);
export const deleteReservation = (id) =>
  axios.delete(`${API_URL}/reservation/deletereservation/${id}`);
export const initializeTransaction = (transactionData) =>
  axios.post(`${API_URL}/donation/initialize-transaction`, transactionData, {
    headers: { "Content-Type": "application/json" },
  });
export const getDonationsByEvent = (eventId) =>
  axios.get(`${API_URL}/donation/donate/${eventId}`);
export const uploadMedia = (mediaData) =>
  axios.post(`${API_URL}/media/media`, mediaData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
export const getMediaByEvent = (eventId) =>
  axios.get(`${API_URL}/media/media/event/${eventId}`);
export const deleteMediaById = (id) =>
  axios.delete(`${API_URL}/media/media/${id}`);
export const createNotification = (notificationData) =>
  axios.post(`${API_URL}/notifications`, notificationData);
export const getNotificationsByEvent = (eventId) =>
  axios.get(`${API_URL}/notifications/${eventId}`);
export const getEventQR = (eventId, phone) =>
  axios.get(`${API_URL}/users/generate-qr/${eventId}/${phone}`);
