import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { FaHome, FaCalendarAlt, FaSignOutAlt } from "react-icons/fa";
import useStore from "../store/event-store";

const Sidenav = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { logout } = useStore();

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  return (
    <div className="h-full w-full md:w-44 bg-[#545822] p-4 flex flex-col justify-between">
      {/* Top Section */}
      <div className="flex flex-col space-y-4 pt-14">
        <Link
          to="/"
          className={`text-white text-lg font-semibold flex items-center space-x-2 p-2 rounded-md ${
            pathname === "/" ? "bg-[#C7CAAA]" : ""
          } hover:bg-[#C7CAAA]`}
        >
          <FaHome size={20} />
          <span>Home</span>
        </Link>
        <Link
          to="/events"
          className={`text-white text-lg font-semibold flex items-center space-x-2 p-2 rounded-md ${
            pathname === "/events" ? "bg-[#C7CAAA]" : ""
          } hover:bg-[#C7CAAA]`}
        >
          <FaCalendarAlt size={20} />
          <span>Events</span>
        </Link>
      </div>

      {/* Bottom Section - Logout */}
      <div>
        <Link
          to="/"
          className="text-white text-lg font-semibold flex items-center space-x-2 p-2 rounded-md hover:bg-[#C7CAAA]"
          onClick={handleLogout}
        >
          <FaSignOutAlt size={20} />
          <span>Logout</span>
        </Link>
      </div>
    </div>
  );
};

export default Sidenav;
