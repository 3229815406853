import React, { useState } from 'react';
import { FaEllipsisH } from "react-icons/fa";
import nodata from "../assets/nodata.jpg";
import { useNavigate } from 'react-router-dom';
import CreateEventForm from './maineventform';
import { deleteEvent } from '../services/api';
import { toast, ToastContainer } from 'react-toastify';

const EventsDynamicTable = ({data, onRefresh}) => {
  const [activeTable, setActiveTable] = useState('table1');
  const [menuOpen, setMenuOpen] = useState(null);
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalOpen = ()=>{
    setIsModalOpen(true)
  }


  const handleModalClose = ()=>{
    setIsModalOpen(false)
  }
  
  const handleTableSwitch = (tableId) => {
    setActiveTable(tableId);
  };

  const handleMenuToggle = (index) => {
    setMenuOpen(menuOpen === index ? null : index);
  };

  const handleDeleteEvent = async(eventId)=>{
    try {
      await deleteEvent(eventId);
      toast.success(`Event ${eventId} deleted Successfully!`);
      onRefresh()
    } catch (error) {
      toast.error("Error deleting event");
    }
    
  }


  // Function to get status based on date comparison
  const getStatus = (eventDate) => {
    const today = new Date();
    const eventDay = new Date(eventDate);

    if (today < eventDay) {
      return { status: 'upcoming', bgColor: 'bg-[#00B388]' };
    } else if (today.toDateString() === eventDay.toDateString()) {
      return { status: 'ongoing', bgColor: 'bg-[#FFB946]' };
    } else {
      return { status: 'ended', bgColor: 'bg-[#d33c30]' };
    }
  };

  // Function to format the date to yy-mm-dd
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0]; // Formats date to yyyy-mm-dd
  };

  // Filter upcoming events
  const getUpcomingEvents = (data) => {
    return data.filter(info => new Date(info.date) > new Date());
  };

  // Filter upcoming events
    const getEndedEvents = (data) => {
      return data.filter(info => new Date(info.date) < new Date());
    };

  return (
    <div className="w-full">
      {/* Buttons */}
      <div className='flex flex-col md:flex-row justify-between'>
        <div className="flex justify-start w-full md:w-auto bg-white text-xs md:text-sm rounded-t overflow-hidden">
          <button
            className={`p-2 flex-grow md:w-[142px] ${activeTable === 'table1' ? 'bg-white text-[#002855]'  : 'bg-lightGreen text-black'}`}
            onClick={() => handleTableSwitch('table1')}
          >
            All
          </button>
          <button
            className={`p-2 flex-grow md:w-[142px] ${activeTable === 'table2' ? 'bg-white text-[#002855]' : 'bg-[#00B388] text-white'}`}
            onClick={() => handleTableSwitch('table2')}
          >
            Upcoming
          </button>
          <button
            className={`p-2 flex-grow md:w-[142px] ${activeTable === 'table3' ? 'bg-white text-[#002855]' : 'bg-[#C61531] text-white' }`}
            onClick={() => handleTableSwitch('table3')}
          >
            Ended / Ongoing
          </button>
        </div>
         <button className="p-3 mt-2 md:mt-0 w-full md:w-auto bg-[#545822] text-white" onClick={handleModalOpen}  style={{borderTopRightRadius:"10px"}}> {/* onClick={() => navigate("/createevent")} */}
          Create an Event
        </button>
      </div>

      {/* Tables */}
      <div className="bg-white p-2 md:p-6 shadow rounded-b w-full">
      {activeTable === 'table1' && (
          <div className="overflow-x-auto" style={{ height: '370px', fontSize: '12px' }}>
            {/* Table Header */}
            <div className="hidden md:grid grid-cols-6 gap-4 text-center p-2 font-semibold">
              <div>EventID</div>
              <div>Event Location</div>
              <div>Event Type</div>
              <div>Date Created</div>
              <div>Status</div>
              <div>Action</div>
            </div>

            {data.length === 0 ? (
              // Display "No Events Yet" Image when no data is available
              <div className="flex justify-center items-center h-full">
                <img src={nodata} width={"200px"} height={"200px"} alt="No Events Yet" />
              </div>
            ) : (
              // Display the table rows when data is available
              data.map((info, index) => {
                const { status, bgColor } = getStatus(info.date);

                return (
                  <div
                    key={index}
                    className="grid grid-cols-2 md:grid-cols-6 gap-4 items-center text-center border border-gray-300 mb-2 p-4 relative"
                    // onClick={() => navigate(`/dashboard/${info.eventid}`)}
                  >
                    <div>{info.eventid}</div>
                    <div>{info.location}</div>
                    <div>{info.type}</div>
                    <div>{formatDate(info.date)}</div>
                    <div>
                      <span className={`px-4 py-1 rounded-lg ${bgColor} text-white`}>
                        {status}
                      </span>
                    </div>
                    <div className="relative flex justify-center cursor-pointer" onClick={() => handleMenuToggle(index)}>
                      <FaEllipsisH />
                      {menuOpen === index && (
                        <div className="absolute bg-white w-[111px] text-[12px] right-0 bottom-[-50px] shadow-lg py-1 cursor-pointer z-10">
                          <p className="py-1" onClick={() => navigate(`/dashboard/${info.eventid}`)}>View</p>
                          <p className="py-1" onClick={() => navigator.clipboard.writeText(info.eventid)}>Copy eventId</p>
                          <p className="text-[#EE5331] py-1"onClick={() => handleDeleteEvent(info.eventid)}>Delete</p>
                          
                        </div>
                      )}
                    </div>
                  </div>
                );
              })
            )}
          </div>
        )}
        {/* Repeat similar blocks for table2 and table3 if necessary */}
        {activeTable === 'table2' && (
          <div className="overflow-x-auto" style={{ height: '370px', fontSize: '12px' }}>
            {/* Table Header */}
            <div className="hidden md:grid grid-cols-6 gap-4 text-center p-2 font-semibold">
              {/* <div></div> Empty cell for the image column */}
              <div>EventID</div>
              <div>Event Location</div>
              <div>Event Type</div>
              <div>Date Created</div>
              <div>Status</div>
              <div>Action</div>
            </div>

            {getUpcomingEvents(data).length === 0 ? (
              // Display "No Events Yet" Image when no data is available
              <div className="flex justify-center items-center h-full">
                <img src={nodata} alt="No Events Yet" width={"200px"} height={"200px"} />
              </div>
            ) : (

              
            getUpcomingEvents(data).map((info, index) => {
              const { status, bgColor } = getStatus(info.date);
             return (
              <div key={index} className="grid grid-cols-2 md:grid-cols-6 gap-4 items-center text-center border border-gray-300 mb-2 p-4 relative">
                {/* <div className="flex justify-center md:justify-start">
                  <img src="/assets/mask.svg" alt="branch" width="50" height="50" className="rounded-full" />
                </div> */}
                <div>{info.eventid}</div>
                <div>{info.location}</div>
                <div>{info.type}</div>
                <div>{formatDate(info.date)}</div>
                <div>
                  <span className={`px-4 py-1 rounded-lg ${bgColor} text-white`}>
                    {status}
                  </span>
                </div>
                <div className="relative flex justify-center cursor-pointer" onClick={() => handleMenuToggle(index)}>
                  <FaEllipsisH />
                  {menuOpen === index && (
                    <div className="absolute bg-white w-[111px] text-[12px] right-0 bottom-[-50px] shadow-lg py-1 cursor-pointer z-10">
                      <p className="py-1" onClick={() => navigate(`/dashboard/${info.eventid}`)}>View</p>
                      <p className="py-1" onClick={() => navigator.clipboard.writeText(info.eventid)}>Copy eventId</p>
                      <p className="text-[#EE5331] py-1" onClick={() => handleDeleteEvent(info.eventid)}>Delete</p>
                    </div>
                  )}
                </div>
              </div>
            )})
              
            )}

            
          </div>
        )}
         {/* Repeat similar blocks for table2 and table3 if necessary */}
         {activeTable === 'table3' && (
          <div className="overflow-x-auto" style={{ height: '370px', fontSize: '12px' }}>
            {/* Table Header */}
            <div className="hidden md:grid grid-cols-6 gap-4 text-center p-2 font-semibold">
              {/* <div></div> Empty cell for the image column */}
              <div>EventID</div>
              <div>Event Location</div>
              <div>Event Type</div>
              <div>Date Created</div>
              <div>Status</div>
              <div>Action</div>
            </div>

            {getEndedEvents(data).length === 0 ? (
              // Display "No Events Yet" Image when no data is available
              <div className="flex justify-center items-center h-full">
                <img src={nodata} width={"200px"} height={"200px"} alt="No Events Yet" />
              </div>
            ) : (
              getEndedEvents(data).map((info, index) => {
                const { status, bgColor } = getStatus(info.date);
               return (
                <div key={index} className="grid grid-cols-2 md:grid-cols-6 gap-4 items-center text-center border border-gray-300 mb-2 p-4 relative">
                  {/* <div className="flex justify-center md:justify-start">
                    <img src="/assets/mask.svg" alt="branch" width="50" height="50" className="rounded-full" />
                  </div> */}
                  <div>{info.eventid}</div>
                  <div>{info.location}</div>
                  <div>{info.type}</div>
                  <div>{formatDate(info.date)}</div>
                  <div>
                    <span className={`px-4 py-1 rounded-lg ${bgColor}  text-white`}>
                      {status}
                    </span>
                  </div>
                  <div className="relative flex justify-center cursor-pointer" onClick={() => handleMenuToggle(index)}>
                    <FaEllipsisH />
                    {menuOpen === index && (
                      <div className="absolute bg-white w-[111px] text-[12px] right-0 bottom-[-50px] shadow-lg py-1 cursor-pointer z-10">
                        <p className="py-1" onClick={() => navigate(`/dashboard/${info.eventid}`)}>View</p>
                        <p className="py-1" onClick={() => navigator.clipboard.writeText(info.eventid)}>Copy eventId</p>
                        <p className="text-[#EE5331] py-1" onClick={() => handleDeleteEvent(info.eventid)}>Delete</p>
                      </div>
                    )}
                  </div>
                </div>
              )})
            )
          }
          </div>
        )}
      </div>
      <CreateEventForm isModalOpen={isModalOpen} onClose={handleModalClose} onRefresh={onRefresh}/>
      <ToastContainer/>
    </div>
  );
};

export default EventsDynamicTable;

