import React from "react";

const Loader = ({ children }) => {
  const containerStyle = {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "150px",
    height: "150px",
  };

  const loaderStyle = {
    border: "10px solid #f3f3f3", // Light grey
    borderTop: "10px solid #d3e537", // white
    borderRadius: "50%",
    width: "100%",
    height: "100%",
    animation: "spin 2s linear infinite",
  };

  const childStyle = {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    color: "white",
  };

  const keyframes = `
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  `;

  return (
    <div className="flex items-center justify-center ">
      <style>{keyframes}</style>
      <div style={containerStyle}>
        <div style={loaderStyle}></div>
        <div style={childStyle}>{children}</div>
      </div>
    </div>
  );
};

export default Loader;
