import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createReservation, getUser } from "../services/api";
import { useNavigate } from "react-router-dom";
import useStore from "../store/event-store";
import Loader from "./loader";

const programs = [
  "Select",
  "Just Me",
  "Me + 1",
  // "Me + 2",
  // "Small family size (5)",
  // "Large family size (10)",
];

const ReservationBox = ({ closeReservationBox, eventid }) => {
  const navigate = useNavigate();
  const { setReservationPhone, setGuestName } = useStore();

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    size: programs[0],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    switch (formData.size) {
      case "Just Me":
        formData.size = 1;
        break;
      case "Me + 1":
        formData.size = 2;
        break;
      case "Me + 2":
        formData.size = 3;
        break;
      case "Small family size (5)":
        formData.size = 5;
        break;
      case "Large family size (10)":
        formData.size = 10;
        break;
      default:
        break;
    }
    formData.eventid = eventid;

    try {
      // Start loading and set the initial message
      setLoading(true);
      setMessage("Making reservation...");

      // Make the reservation request
      const response = await createReservation(formData);
      // console.log("hereee", response);

      // Check if a user is returned from the response
      if (response.data && response.data.user) {
        // Fetch additional user details if necessary
        const res = await getUser(response.data.user);
        const { name } = res.data;
        setGuestName(name); // Set guest name in state
      }

      // If reservation is successful, close the reservation box and show success toast
      if (response.data) {
        setReservationPhone(formData.phone); // Set the reservation phone in the store
        toast.success("Reservation Successful"); // Show success message
        setTimeout(() => {
          closeReservationBox(); // Close the reservation box
        }, 3000);
      }
    } catch (error) {
      // Handle errors
      setLoading(false);

      if (
        error.response?.data?.error ===
        "Error checking reservation: User not found"
      ) {
        toast.error("You need to register to make a reservation");
        navigate("/guestregistration");
      } else {
        toast.error(
          "Error making reservation, perhaps reservations have been made"
        );

        setTimeout(() => {
          closeReservationBox(); // Close the reservation box
        }, 2000);
      }
    } finally {
      // Stop loading regardless of success or failure
      setLoading(false);
    }

    // try {
    //   const response = await createReservation(formData);
    //   console.log("reservaation response", response.data);
    //   if (response.data.user) {
    //     const res = await getUser(response.data.user);

    //     const { name } = res.data;
    //     console.log("guestName", name);
    //     setGuestName(name);
    //   }

    //   setLoading(true);
    //   setMessage("Making reservation...");
    //   toast.success("Reservation Successful");

    //   if (response.data) {
    //     closeReservationBox();
    //     toast.success("Reservation Successful");
    //     setLoading(false);
    //     setReservationPhone(formData.phone); // Set the reservation phone in the store
    //   }
    // } catch (error) {
    //   // console.error("Error submitting form:", error.response.data.error);
    //   setLoading(false);
    //   if (
    //     error.response.data.error ===
    //     "Error checking reservation: User not found"
    //   ) {
    //     toast.error("You need to register to make reservation");

    //     navigate("/guestregistration");
    //   }

    //   toast.error(
    //     "Error making reservation, perhaps reservations have been made"
    //   );
    // }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      {loading ? (
        <Loader>{message}</Loader>
      ) : (
        <div className="bg-white p-4 rounded-lg shadow-lg sm:w-3/4 md:w-1/3 lg:w-1/4 relative z-50">
          <div className="flex justify-end">
            <button
              onClick={closeReservationBox}
              className="px-4 py-2 bg-red-500 text-white hover:bg-red-700 transition rounded"
            >
              Close
            </button>
          </div>
          <h2 className="text-xl font-semibold text-center">RSVP</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-gray-700">Full Name:</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="mt-1 p-2 border rounded w-full"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Phone Number:</label>
              <input
                type="text"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                className="mt-1 p-2 border rounded w-full"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Select Table Size:</label>
              <select
                name="size"
                value={formData.size}
                onChange={handleChange}
                className="w-full mt-1 p-2 border border-gray-300 rounded"
                required
              >
                {programs.map((program, index) => (
                  <option key={index} value={program}>
                    {program}
                  </option>
                ))}
              </select>
            </div>
            <button
              type="submit"
              className="px-4 py-2 w-full bg-green-600 text-white hover:bg-[#d3e999] transition rounded"
            >
              Reserve
            </button>
          </form>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default ReservationBox;
