import { RouterProvider } from "react-router-dom";
import router from "./routes/routes";

function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;

// import React from "react";
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import GalleryPage from "./pages/GalleryPage";
// import VideosPage from "./pages/VideosPage";
// // import Dashboard from "./pages/dashboard";
// import Layout from "./layout/layout";
// // import LoginForm from "./components/login";
// // import RegistrationForm from "./components/register";
// // import CreateEventForm from "./components/eventform";
// import PreEventsUploads from "./pages/create-pre-event";
// import AppLandingPage from "./pages/app-landing-page";
// import GuestView from "./pages/guestview";
// import PreEventPage from "./pages/pre-event";
// import PrivateRoute from "./routes/privateroutes";
// // import AllEvents from "./pages/allevents";
// // import GuestRegistrationForm from "./components/guestregister";
// import Livestream from "./pages/livestream";
// import Login from "./components/login";
// import Register from "./components/register";
// import GuestRegister from "./components/guestregister";
// import EventsDashboard from "./pages/eventsdashboard";
// import EventDashboard from "./pages/eventdashboard";
// // import CreateEventForm from "./components/maineventform";

// const App = () => (
//   <Router>
//     <Routes>
//       <Route path="/" element={<AppLandingPage />} />
//       {/* <Route path="/oldregistration" element={<RegistrationForm />} /> */}
//       <Route path="/registration" element={<Register />} />
//       {/* <Route path="/oldguestregistration" element={<GuestRegistrationForm />} /> */}
//       <Route path="/guestregistration" element={<GuestRegister />} />
//       <Route path="/guestview/:eventId" element={<GuestView />} />
//       {/* <Route path="/oldlogin" element={<LoginForm />} /> */}
//       <Route path="/login" element={<Login />} />
//       <Route path="/preeventpage/:eventid" element={<PreEventPage />} />
//       <Route path="/gallery/:eventid" element={<GalleryPage />} />
//       <Route path="/videos/:eventid" element={<VideosPage />} />
//       <Route path="/livestream/:eventid" element={<Livestream />} />

//       <Route path="/" element={<Layout />}>
//         {/* <Route
//           path="dashboard/:eventid/*"
//           element={
//             <PrivateRoute>
//               <Dashboard />
//             </PrivateRoute>
//           }
//         /> */}
//         <Route
//           path="dashboard/:eventid/*"
//           element={
//             <PrivateRoute>
//               <EventDashboard />
//             </PrivateRoute>
//           }
//         />
//         <Route
//           path="events"
//           element={
//             <PrivateRoute>
//               <EventsDashboard />
//             </PrivateRoute>
//           }
//         />
//         {/* <Route
//           path="events"
//           element={
//             <PrivateRoute>
//               <AllEvents />
//             </PrivateRoute>
//           }
//         /> */}
//         {/* <Route
//           path="createevent"
//           element={
//             <PrivateRoute>
//               <CreateEventForm />
//             </PrivateRoute>
//           }
//         /> */}
//         {/* <Route
//           path="createevent"
//           element={
//             <PrivateRoute>
//               <CreateEventForm />
//             </PrivateRoute>
//           }
//         /> */}
//         <Route
//           path="preevent/:eventid"
//           element={
//             <PrivateRoute>
//               <PreEventsUploads />
//             </PrivateRoute>
//           }
//         />
//       </Route>
//     </Routes>
//   </Router>
// );

// export default App;
