import React, { useState, useEffect } from "react";
import axios from "axios";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "../leaflet.css";
import L from "leaflet";
import { Link } from "react-router-dom";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("../assets/map-images/marker-icon-2x.png"),
  iconUrl: require("../assets/map-images/marker-icon.png"),
  shadowUrl: require("../assets/map-images/marker-shadow.png"),
});

const EventDetails = ({
  eventname,
  theme,
  color,
  location,
  date,
  phoneNumber,
  description,
  closeModal,
}) => {
  const [coordinates, setCoordinates] = useState(null);

  useEffect(() => {
    if (location) {
      const fetchCoordinates = async () => {
        try {
          const response = await axios.get(
            `https://api.opencagedata.com/geocode/v1/json?q=${location.name}&key=${process.env.REACT_APP_APIKEY}`
          );
          const { lat, lng } = response.data.results[0].geometry;
          setCoordinates([lat, lng]);
        } catch (error) {
          console.error("Error fetching coordinates: ", error);
        }
      };
      fetchCoordinates();
    } else {
      setCoordinates([location.latitude, location.longitude]);
    }
  }, [location]);

  // Construct Google Maps deep link
  const googleMapsLink = coordinates
    ? `https://www.google.com/maps/search/?api=1&query=${coordinates[0]},${coordinates[1]}`
    : null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 py-3">
      <div className="bg-white p-6 rounded-lg shadow-lg sm:w-3/4 md:w-1/2 lg:w-1/3 relative z-50 py-3 overflow-y-scroll max-h-[85vh]">
        <div className="flex justify-end">
          <button
            onClick={closeModal}
            className="px-4 py-2 bg-red-500 text-white hover:bg-red-700 transition rounded"
          >
            Close
          </button>
        </div>
        <h2 className="text-2xl font-semibold text-start mb-4">
          Event Details
        </h2>
        <div className="text-start">
          {eventname && (
            <p className="text-lg mb-2">
              <strong>Event Name: </strong>
              {eventname}
            </p>
          )}
          {theme && (
            <p className="text-lg mb-2">
              <strong>Event Theme: </strong>
              {theme}
            </p>
          )}

          {color && (
            <p className="text-lg mb-2">
              <strong>Dress Code: </strong>
              <span style={{ color: color === "white" ? "black" : color }}>
                {color}
              </span>
            </p>
          )}

          <p className="text-lg mb-2">
            <strong>Location: </strong>
            {location.name}
          </p>
          <p className="text-lg mb-2">
            <strong>Date: </strong>
            {date}
          </p>

          {phoneNumber && (
            <p className="text-lg mb-2">
              <strong>Contact Person: </strong>
              {phoneNumber}
            </p>
          )}

          {description && (
            <p className="text-lg mb-2">
              <strong>More Details: </strong>
              {description}
            </p>
          )}
        </div>
        {coordinates && (
          <div className="mt-4">
            <MapContainer
              center={coordinates}
              zoom={13}
              className="h-64 w-full"
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
              <Marker position={coordinates}>
                <Popup>{location.name}</Popup>
              </Marker>
            </MapContainer>

            {/* Google Maps deep link */}

            <div className="mt-4 text-center pb-3">
              <Link
                to={googleMapsLink}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block px-4 py-2 bg-custom-gradient-new text-white font-semibold rounded hover:bg-darkOlive transition w-full sm:w-auto text-center"
              >
                Open in Google Maps
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EventDetails;

// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
// import "leaflet/dist/leaflet.css";
// import "../leaflet.css";
// import L from "leaflet";

// delete L.Icon.Default.prototype._getIconUrl;

// L.Icon.Default.mergeOptions({
//   iconRetinaUrl: require("../assets/map-images/marker-icon-2x.png"),
//   iconUrl: require("../assets/map-images/marker-icon.png"),
//   shadowUrl: require("../assets/map-images/marker-shadow.png"),
// });

// const EventDetails = ({
//   eventname,
//   theme,
//   color,
//   location,
//   date,
//   phoneNumber,
//   description,
//   closeModal,
// }) => {
//   const [coordinates, setCoordinates] = useState(null);

//   useEffect(() => {
//     if (location) {
//       const fetchCoordinates = async () => {
//         try {
//           const response = await axios.get(
//             `https://api.opencagedata.com/geocode/v1/json?q=${location.name}&key=${process.env.REACT_APP_APIKEY}`
//           );
//           const { lat, lng } = response.data.results[0].geometry;
//           setCoordinates([lat, lng]);
//         } catch (error) {
//           console.error("Error fetching coordinates: ", error);
//         }
//       };
//       fetchCoordinates();
//     } else {
//       setCoordinates([location.latitude, location.longitude]);
//     }
//   }, [location]);

//   return (
//     <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
//       <div className="bg-white p-6 rounded-lg shadow-lg sm:w-3/4 md:w-1/2 lg:w-1/3 relative z-50">
//         <div className="flex justify-end">
//           <button
//             onClick={closeModal}
//             className="px-4 py-2 bg-red-500 text-white hover:bg-red-700 transition rounded"
//           >
//             Close
//           </button>
//         </div>
//         <h2 className="text-2xl font-semibold text-center mb-4">
//           Event Details
//         </h2>
//         <div className="text-center">
//           <p className="text-lg mb-2">
//             <strong>Event Name: </strong>
//             {eventname}
//           </p>
//           <p className="text-lg mb-2">
//             <strong>Event Theme: </strong>
//             {theme}
//           </p>
//           <p className="text-lg mb-2">
//             <strong>Dress Code: </strong>
//             <span style={{ color: color == "white" ? "black" : color }}>
//               {color}
//             </span>
//           </p>
//           <p className="text-lg mb-2">
//             <strong>Location: </strong>
//             {location.name}
//           </p>
//           <p className="text-lg mb-2">
//             <strong>Date: </strong>
//             {date}
//           </p>
//           <p className="text-lg mb-2">
//             <strong>Contact Person: </strong>
//             {phoneNumber}
//           </p>
//           <p className="text-lg mb-2">
//             <strong>More Details: </strong>
//             {description}
//           </p>
//         </div>
//         {coordinates && (
//           <div className="mt-4">
//             <MapContainer
//               center={coordinates}
//               zoom={13}
//               className="h-64 w-full"
//             >
//               <TileLayer
//                 url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//                 attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//               />
//               <Marker position={coordinates}>
//                 <Popup>{location.name}</Popup>
//               </Marker>
//             </MapContainer>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default EventDetails;
