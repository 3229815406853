import React, { useState } from 'react';
import { FaEllipsisH } from "react-icons/fa";
import nodata from "../assets/nodata.jpg";
import { useNavigate, useParams } from 'react-router-dom';
import { deleteMediaById, deleteReservation } from '../services/api';
import ListingCard from './listingcard';
import { toast, ToastContainer } from 'react-toastify';


const EventDynamicTable = ({reservationData,mediaData,donationData,onRefresh}) => {
  const [activeTable, setActiveTable] = useState('table1');
  const [menuOpen, setMenuOpen] = useState(null);
  const navigate = useNavigate()
  const {eventid} = useParams();


  const handleTableSwitch = (tableId) => {
    setActiveTable(tableId);
  };

  const handleMenuToggle = (index) => {
    setMenuOpen(menuOpen === index ? null : index);
  };


  const formatDateTime = (isoString)=>{
    const date = new Date(isoString);
  
    // Format date as "YYYY-MM-DD HH:MM AM/PM"
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
    };
  
    return date.toLocaleString('en-US', options);
  }

  const handleMediaDelete = async (id)=>{
    try {
      await deleteMediaById(id);
      // toast.success("Media deleted Successfully!")
        onRefresh();
    } catch (error) {
      toast.error("unable to delete media!")
    }
  }

  const handleDownload = async (url) => {
    try {
      // Fetch the file data
      const response = await fetch(url);
      const blob = await response.blob(); // Convert the response to a Blob (binary large object)
  
      // Create a temporary URL for the Blob and download it
      const link = document.createElement('a');
      const blobUrl = window.URL.createObjectURL(blob); // Create a URL for the blob
      link.href = blobUrl;
  
      // Set the file name for download
      const fileName = url.split('/').pop(); // Extract the file name from the URL
      link.setAttribute('download', fileName);
  
      // Append the link, trigger the download, and clean up
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  
      // Revoke the Blob URL to free up memory
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Download failed:", error);
      alert("Failed to download the file.");
    }
  };
  
  
  const handleDeleteReservation = async (id)=>{
    try {
      await deleteReservation(id);
      onRefresh();
    } catch (error) {
      toast.error("unable to delete reservation!")
    }
    
  };

  function convertToReadableDateTime(isoString) {
    const date = new Date(isoString);
  
    // Get the date components
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based, so we add 1
    const day = date.getDate().toString().padStart(2, "0");
  
    // Get the time components
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
  
    // Format the date and time
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
  


  // const downloadCSV = () => {
  //   // Prepare the data as plain text
  //   const headers = "Name\tEmail\tPhone Number\tDate and Time\tReservation Size\n";
  //   const rows = reservationData.map(info => [
  //     info.user.name,
  //     info.user.email,
  //     info.user.phone,
  //     formatDateTime(info.reservedAt),
  //     info.size
  //   ].join("\t")).join("\n");
  
  //   const txtContent = headers + rows;
  
  //   // Create a Blob from the TXT content
  //   const blob = new Blob([txtContent], { type: "text/plain" });
  
  //   // Create a download link
  //   const link = document.createElement("a");
  //   link.href = URL.createObjectURL(blob);
  //   link.download = "reservations.txt"; // File name for the TXT file
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };

  const downloadCSV = () => {
    // Headers for CSV
    const headers = ["Name", "Email", "Phone Number", "Date and Time", "Reservation Size"];
  
    // Map through the reservationData to get table data
    const csvRows = reservationData.map(info => [
      `"${info.user.name}"`,             // Wrap in quotes to handle special characters
      `"${info.user.email}"`,            // Wrap in quotes to handle special characters
      `"${info.user.phone}"`,            // Wrap in quotes to handle special characters
      `"${formatDateTime(info.reservedAt)}"`,  // Wrap in quotes to handle special characters
      `"${info.size}"`                   // Wrap in quotes to handle special characters
    ]);
  
    // Combine headers and rows, ensuring proper CSV format
    const csvContent = [
      headers.join(","),                 // Join headers with commas
      ...csvRows.map(row => row.join(",")) // Join each row's values with commas
    ].join("\n");                        // Separate rows by newlines
  
    // Add UTF-8 BOM for Excel compatibility
    const bom = "\uFEFF";
  
    // Create a Blob from the CSV content (with BOM)
    const blob = new Blob([bom + csvContent], { type: "text/csv;charset=utf-8;" });
  
    // Create a download link
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "reservations.csv";  // File name for the CSV
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
  
 // Format the total amount as Naira currency (₦)
 const formatAmount = (amount)=>{
  const formattedTotal = new Intl.NumberFormat('en-NG', {
    style: 'currency',
    currency: 'NGN'
  }).format(amount);
  return formattedTotal;
 }



  return (
    <div className="w-full">
      {/* Buttons */}
      <div className='flex flex-col md:flex-row justify-between'>
        <div className="flex justify-start w-full md:w-auto bg-white text-xs md:text-sm rounded-t overflow-hidden">
          <button
            className={`p-2 flex-grow md:w-[142px] ${activeTable === 'table1' ? 'bg-white text-[#002855]'  : 'bg-[#0277E6] text-white'}`}
            onClick={() => handleTableSwitch('table1')}
          >
            Reservations
          </button>
          <button
            className={`p-2 flex-grow md:w-[142px] ${activeTable === 'table2' ? 'bg-white text-[#002855]'  : 'bg-[#00B388] text-white'}`}
            onClick={() => handleTableSwitch('table2')}
          >
            Media
          </button>
          <button
            className={`p-2 flex-grow md:w-[142px] ${activeTable === 'table3' ? 'bg-white text-[#002855]' : 'bg-[#FFB946] text-white' }`}
            onClick={() => handleTableSwitch('table3')}
          >
            Donations
          </button>
        </div>
        <button className="p-3 mt-2 md:mt-0 w-full md:w-auto bg-[#545822] text-white"  onClick={() => navigate(`/preevent/${eventid}`)} style={{borderTopRightRadius:"10px"}}> 
          Upload Media
        </button>
      </div>

      {/* Tables */}
      <div className="bg-white p-2 md:p-6 shadow rounded-b w-full">
      
      {activeTable === 'table1' && (
          <div className="overflow-x-auto" style={{ height: '370px', fontSize: '12px'}}>
            {/* button to download csv file for reservations */}
            <button 
              onClick={downloadCSV} 
              className="bg-blue-500 text-white p-2 rounded mb-4"
            >
              Download CSV
            </button>
            {/* Table Header */}
            <div className="hidden md:grid grid-cols-6 gap-4 text-center p-2 font-semibold">
              <div>Name</div>
              <div>Email</div>
              <div>Phone Number</div>
              <div>Date and Time</div>
              <div>Reservation Size</div>
              <div>Action</div>
            </div>

            {reservationData.length === 0 ? (
              // Display "No Events Yet" Image when no data is available
              <div className="flex justify-center items-center h-full">
                <img src={nodata} width={"200px"} height={"200px"} alt="No Events Yet" />
              </div>
            ) : (
              // Display the table rows when data is available
              reservationData.map((info, index) => {
                // const { status, bgColor } = getStatus(info.date);
                
                return (
                  <div
                    key={index}
                    className="grid grid-cols-2 md:grid-cols-6 gap-4 items-center text-start md:text-center border border-gray-300 mb-2 p-4 relative overflow-x-scroll md:overflow-x-hidden"
                  >
                    <div><span className='flex flex-row md:hidden'>Name</span>{info.user.name}</div>
                    <div><span className='flex md:hidden'>Email</span>{info.user.email}</div>
                    <div><span className='flex md:hidden'>Phone Number</span>{info.user.phone}</div>
                    <div><span className='flex md:hidden'>Reservation Time</span>{formatDateTime(info.reservedAt)}</div>
                    <div><span className='flex md:hidden'>Num of Seat(s) Requested</span>{info.size}</div>
                    <div className="relative flex justify-center cursor-pointer" onClick={() => handleMenuToggle(index)}>
                    {/* <span className='flex flex-col md:hidden'>Action</span> */}
                      <FaEllipsisH />
                      {menuOpen === index && (
                        <div className="absolute bg-white w-[111px] text-[12px] right-0 bottom-[-50px] shadow-lg py-1 cursor-pointer z-10">
                          <p className="text-[#EE5331] py-1" onClick={() => handleDeleteReservation(info._id)}>Delete</p>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })
            )}
          </div>
        )}
        {/* Repeat similar blocks for table2 and table3 if necessary */}
        {activeTable === 'table2' && (
          <div className="overflow-x-auto pb-4" style={{ height: '370px', fontSize: '12px' }}>
            {/* Horizontal scrolling container */}
            <div className="flex flex-row gap-4 justify-start items-center overflow-x-auto whitespace-nowrap p-3">
              {mediaData.length === 0 ? (
                // Display "No Events Yet" Image when no data is available
                <div className="flex justify-center items-center h-full">
                  <img src={nodata} alt="No Events Yet" width={"200px"} height={"200px"} />
                </div>
              ) : (
                               
                mediaData.map((info, index) => (
                  <ListingCard
                    key={index}
                    person={info.user.name}
                    phone={info.user.phone}
                    src={info.url}
                    type={info.type}
                    onDelete={() => handleMediaDelete(info._id)}
                    onDownload={() => handleDownload(info.url)}
                  />
                ))
              )}
            </div>
          </div>
        )}


         {/* Repeat similar blocks for table2 and table3 if necessary */}
         {activeTable === 'table3' && (
          <div className="overflow-x-auto" style={{ height: '370px', fontSize: '12px' }}>
            {/* Table Header */}
            <div className="hidden md:grid grid-cols-6 gap-4 text-center p-2 font-semibold">
              {/* <div></div> Empty cell for the image column */}
              <div>FullName</div>
              <div>PhoneNumber</div>
              <div>(₦)Amount</div>
              <div>Date and Time</div>
              <div>Reference</div>
              <div>Action</div>
            </div>

            {donationData.length === 0 ? (
              // Display "No Events Yet" Image when no data is available
              <div className="flex justify-center items-center h-full">
                <img src={nodata} width={"200px"} height={"200px"} alt="No Events Yet" />
              </div>
            ) : (
              donationData.map((info, index) => {
                
               return (
                <div key={index} className="grid grid-cols-2 md:grid-cols-6 gap-4 items-center text-start md:text-center  border border-gray-300 mb-2 p-4 relative">
                  {/* <div className="flex justify-center md:justify-start">
                    <img src="/assets/mask.svg" alt="branch" width="50" height="50" className="rounded-full" />
                  </div> */}
                  <div><span className='flex flex-row md:hidden'>Name</span>{info.user.name}</div>
                  <div><span className='flex md:hidden'>PhoneNumber</span>{info.user.phone}</div>
                  <div><span className='flex md:hidden'>Amount</span>{formatAmount(info.amount)}</div>
                  <div><span className='flex md:hidden'>Donated At</span>{convertToReadableDateTime(info.createdAt)}</div>
                  <div><span className='flex md:hidden'>Reference</span>{info.reference}</div>
                  <div className="relative flex justify-center" onClick={() => handleMenuToggle(index)}>
                    <FaEllipsisH />
                    {menuOpen === index && (
                      <div className="relative flex justify-center cursor-pointer" onClick={() => handleMenuToggle(index)}>
                      <FaEllipsisH />
                      {menuOpen === index && (
                        <div className="absolute bg-white w-[111px] text-[12px] right-0 bottom-[-50px] shadow-lg py-1 cursor-pointer z-10">
                          <p className="text-green-800 py-1" onClick={() => alert("not yet implemented")}>Send Message</p>
                        </div>
                      )}
                    </div>
                    )}
                  </div>
                </div>
              )})
            )
          }
          </div>
        )}
      </div>
      <ToastContainer/>
    </div>
  );
};

export default EventDynamicTable;

