import { useState } from "react";
import { Link } from "react-router-dom";
import avatar from "../assets/avatar_7.jpg";
import logo from "../assets/mainlogo-removebg.png";

const AvatarMenue = () => {
  return (
    <div className="relative border-t lg:border-none">
      <div className="">
        <div className="hidden w-10 h-10 outline-none rounded-full ring-offset-2 ring-gray-200 lg:focus:ring-2 lg:block">
          <img
            src={avatar}
            className="w-full h-full rounded-full"
            alt="profile"
          />
        </div>
      </div>
    </div>
  );
};

const NavBar = ({ onClick }) => {
  const [state] = useState(false);

  const navigation = [{ title: "Feedback", path: "/hostfeedback" }];

  return (
    <header className="text-base lg:text-sm">
      <div
        className={`h-[70px] bg-[#b2b590] items-center gap-x-14 px-4  lg:flex lg:static ${
          state ? "fixed inset-x-0" : ""
        }`}
      >
        <div className="flex items-center justify-between py-3 lg:py-5 lg:block">
          <Link to={"/home"}>
            <img src={logo} width={120} height={50} alt="Eventaura logo" />
          </Link>

          <button className="md:hidden mb-4" onClick={onClick}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          </button>
        </div>
        <div
          className={`nav-menu flex-1 pb-28 mt-8 overflow-y-auto max-h-screen lg:block lg:overflow-visible lg:pb-0 lg:mt-0 ${
            state ? "" : "hidden"
          }`}
        >
          <ul className="items-center space-y-6 lg:flex lg:space-x-6 lg:space-y-0">
            <form
              onSubmit={(e) => e.preventDefault()}
              className="flex-1 items-center justify-start pb-4 lg:flex lg:pb-0"
            >
              <div className="flex items-center gap-1 px-2  rounded-lg bg-[#797C4E]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6 text-black"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
                <input
                  type="text"
                  placeholder="Search"
                  className="w-full px-2 py-2 text-white bg-[#797C4E] rounded-md outline-none border-none"
                />
              </div>
            </form>
            {navigation.map((item, idx) => {
              return (
                <li key={idx}>
                  <Link
                    to={item.path}
                    className="block text-gray-700 hover:text-gray-900"
                  >
                    {item.title}
                  </Link>
                </li>
              );
            })}
            <AvatarMenue />
          </ul>
        </div>
      </div>
    </header>
  );
};

export default NavBar;
