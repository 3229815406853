import React from "react";
import { Navigate } from "react-router-dom";
import useStore from "../store/event-store";

const PrivateRoute = ({ children }) => {
  const { isLoggedIn } = useStore();
  return isLoggedIn ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
