import { createBrowserRouter, Outlet } from "react-router-dom";
import AppLandingPage from "../pages/app-landing-page";
import Register from "../components/register";
import GuestRegister from "../components/guestregister";
import GuestView from "../pages/guestview";
import Login from "../components/login";
import PreEventPage from "../pages/pre-event";
import GalleryPage from "../pages/GalleryPage";
import VideosPage from "../pages/VideosPage";
import Livestream from "../pages/livestream";
import Layout from "../layout/layout";
import PrivateRoute from "./privateroutes";
import EventDashboard from "../pages/eventdashboard";
import EventsDashboard from "../pages/eventsdashboard";
import PreEventsUploads from "../pages/create-pre-event";
import ErrorPage from "../pages/errorpage";
import FeedbackForm from "../components/feedback";

const router = createBrowserRouter([
  // Client
  {
    path: "/",
    element: <AppLandingPage />,
  },
  {
    path: "/registration",
    element: <Register />,
  },
  {
    path: "/guestregistration",
    element: <GuestRegister />,
  },
  {
    path: "/guestview/:eventId",
    element: <GuestView />,
    // errorElement: <ErrorPage />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/preeventpage/:eventid",
    element: <PreEventPage />,
  },
  {
    path: "/gallery/:eventid",
    element: <GalleryPage />,
  },
  {
    path: "/videos/:eventid",
    element: <VideosPage />,
  },
  {
    path: "/livestream/:eventid",
    element: <Livestream />,
  },
  {
    path: "/error-page",
    element: <ErrorPage />,
  },
  {
    path: "/hostfeedback",
    element: <FeedbackForm />,
  },
  // Host
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        element: (
          <PrivateRoute>
            <Outlet />
          </PrivateRoute>
        ),
        children: [
          {
            path: "dashboard/:eventid/*",
            element: <EventDashboard />,
          },
          {
            path: "events",
            element: <EventsDashboard />,
          },
          {
            path: "preevent/:eventid",
            element: <PreEventsUploads />,
          },
        ],
      },
    ],
  },
]);

export default router;
