const ListingCard = ({ person, phone, src, type, onDelete, onDownload }) => {
    return (
      <div className="rounded-xl bg-white p-4 shadow-lg hover:shadow-xl w-[250px] max-w-[250px] flex-shrink-0">
        <div className="relative flex items-center justify-center h-[150px] overflow-hidden rounded-xl mb-4">
          {/* Check if the media is an image or video */}
          {type === 'image' ? (
            <img src={src} alt="Event Media" className="object-cover w-full h-full" />
          ) : (
            <video controls className="object-cover w-full h-full">
              <source src={src} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
        </div>
  
        <div>
           <p className="text-slate-400 mt-1 text-xs">{type}</p> 
          <h2 className="text-slate-700 text-sm font-semibold truncate">{person}</h2>
          <p className="text-slate-400 mt-1 text-sm">{phone}</p>
        </div>
  
        <div className="mt-4 flex items-center justify-between">
          <button onClick={onDownload} className="text-blue-500 text-sm font-medium">Download</button>
          <button onClick={onDelete} className="text-red-500 text-sm font-medium">
            Delete
          </button>
        </div>
      </div>
    );
  };
  
  export default ListingCard;
  