import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {getDonationsByEvent, getMediaByEvent, getuserReservationsDetailsByEvent } from "../services/api";
import CardSectionEvent from "../components/cardsectionevent";
import { ToastContainer, toast } from "react-toastify";
import EventDynamicTable from "../components/eventdynamictable";

const EventDashboard = () => {

    const { eventid } = useParams();
    const location = useLocation();


    const [userDetails, setUserDetails] = useState([]);
    const [images, setImages] = useState([]);
    const [videos, setVideos] = useState([]);
    const [mediaTable, setMediaTable] = useState([]);
    const [Donations, setDonations] = useState([]);
    const [totalAmount, setTotalAmount] = useState("₦0:00")
    const [refresh, setRefresh] = useState(false);

    const handleRefresh = ()=>{
      setRefresh(prev => !prev)
    };

    function calculateTotalAmount(donations) {
      // Sum up the amounts
      const totalAmount = donations.reduce((sum, donation) => {
        return sum + donation.amount;
      }, 0);
    
      // Format the total amount as Naira currency (₦)
      const formattedTotal = new Intl.NumberFormat('en-NG', {
        style: 'currency',
        currency: 'NGN'
      }).format(totalAmount);
    
      return formattedTotal;
    }
  
    useEffect(() => {
      const fetchReservations = async () => {
        try {
          const res = await getuserReservationsDetailsByEvent(eventid);

          setUserDetails(res.data);

        } catch (error) {
          console.log(error);
        }
      };
  
      if (eventid) {
        fetchReservations();
      }
    }, [eventid, refresh]);
  
  // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const fetchMediaUploads = async () => {
      try {
        const res = await getMediaByEvent(eventid);
        const videoFiles = res.data.filter((media) => media.type === "video");
        const imageFiles = res.data.filter((media) => media.type === "image");

        setVideos(videoFiles);
        setImages(imageFiles);
      } catch (error) {
        toast.error("unable to get media!")
      }
    };

    if (eventid) {
      fetchMediaUploads();
    }
  }, [eventid, refresh]);// eslint-disable-line react-hooks/exhaustive-deps



  // Merge images and videos once they are available
  useEffect(() => {
    if (images.length > 0 || videos.length > 0) {
     
      setMediaTable([...images, ...videos]);
    }
  }, [images, videos]);

  useEffect(()=>{
    const fetchDonations = async ()=>{
      try {
        const res = await getDonationsByEvent(eventid);
        setDonations(res.data);
        setTotalAmount(calculateTotalAmount(res.data))
        
      } catch (error) {
        toast.error("unable to get donations! ",error)
      }
    }

    if (eventid) {
      fetchDonations();
    }
  },[refresh, eventid])
  
  
  const currentUrl = window.location.origin + location.pathname.replace('/dashboard/', '/guestview/') + location.search;

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(currentUrl).then(() => {
      toast.success("copied");
    });
  };
  
  return(
    
    <div className="px-8 max-w-[1650px]"> 
    <div className="flex justify-between pt-5">
    <p className="text-darkOlive font-bold text-sm md:text-3xl ">EventID: {eventid && eventid}</p>
      <button onClick={handleCopyUrl} className="px-4 py-1 bg-[#4f513a] text-[#d3e537] rounded-lg hover:bg-gray-300 hover:text-black">Copy Link</button>
    </div>
    {console.log("rerer",totalAmount)} 
      <CardSectionEvent videoFiles={videos} imageFiles={images} totalAmount={totalAmount} onRefresh={handleRefresh} />
      <div className="pt-2 md:pt-[55px]">
        <p className="text-darkOlive font-bold text-3xl pb-5">Events Summary</p>
        <EventDynamicTable reservationData={userDetails} mediaData={mediaTable} donationData={Donations} onRefresh={handleRefresh}/>
      </div>
      <ToastContainer/>
    </div>
  )
}
  
export default EventDashboard;

